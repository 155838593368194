import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal as BootstrapModal, Form } from 'react-bootstrap';
import { Button, ButtonToolbar } from '@/shared/components/Button';
import FiEdit from 'mdi-react/SquareEditOutlineIcon';
import styled from 'styled-components';
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
} from '@/utils/palette';
import {
  flexFlow, left, paddingLeft, paddingRight, right,
} from '@/utils/directions';


const ModalUpdate = ({
  id,
  content,
  color,
  btn,
  colored,
  header,
  type = "button",
  confirm= () => {}
}) => {
  const [modal, setModal] = useState(false);
  const [note, setNote] = useState(content);

  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const buttonVariant = colored ? `outline-${color}` : color;
  const handleConfirm = async () => {
    


    await confirm(id, note);
    toggle();
  }
  return (
    <>
      {type === "icon" && <span onClick={() => {
        toggle();

      }}><FiEdit className={btn}/></span>}
      <div>
        {
          type !== "icon" && <FiEdit
            className='text-primary'
            style={{cursor: "pointer"}}
            onClick={toggle}
          />
        }
        <StyledModal
          show={modal}
          onHide={toggle}
          color={color}
          colored={colored}
          header={header}
          centered
        >
          <ModalBody className='p-0'>
            
            
                <Form.Group className="mb-0">
                  <Form.Label>Note</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={note}
                    onChange={e => setNote(e.target.value)}
                  />
                </Form.Group>
          </ModalBody>
          <ModalFooter style={{marginBottom: 0}}>
            <Button variant="danger" onClick={toggle}>Cancel</Button>
            <Button variant={buttonVariant} onClick={handleConfirm}>Update</Button>
          </ModalFooter>
        </StyledModal>
      </div>
    </>
  );
};

ModalUpdate.propTypes = {
  id: PropTypes.number,
  content: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  type: PropTypes.string,
  confirm: PropTypes.func
};

ModalUpdate.defaultProps = {
  title: '',
  colored: false,
  header: false,
};

export default ModalUpdate;

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }
  
  flex-flow: ${flexFlow} !important;
`;

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`;

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`;

const ModalHeader = styled.div``;
const ModalBody = styled.div``;

const getColor = (color) => {
  switch (color) {
    case 'primary':
      return colorBlue;
    case 'success':
      return colorAccent;
    case 'warning':
      return colorYellow;
    case 'danger':
      return colorRed;

    default:
      return colorText;
  }
};

const StyledModal = styled(BootstrapModal).withConfig({
  shouldForwardProp: prop => !['colored', 'header'].includes(prop),
})`
  
  .modal-dialog {
    max-width: 800px;
  }

  .modal-content {
    border-radius: 10px;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 10px 40px 10px 40px;
    background-color: ${colorBackground};
    color: ${colorText};
  }
  
  ${ModalTitleIcon} {
    color: ${props => getColor(props.color)};
  }

  ${props => props.colored && `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${getColor(props.color)};
    }
  `}

  ${props => props.header && `
    
    .modal-dialog {
      max-width: 520px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${getColor(props.color)};
    }
  `}
`;

// endregion
