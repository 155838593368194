import React, {
  useEffect,
  useState
} from 'react';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
// import ProfileMain from '../../../containers/Account/Profile/components/ProfileMain';
import styled from 'styled-components';
import { colorAccent } from '@/utils/palette';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Summary from '../../../components/summary/Summary';
import axios from 'axios';
import { useSelector } from 'react-redux';
import baseUrl from '../../../axiosBase';
// import { Col } from 'react-bootstrap';

export const getStatusClass = (state) => {
  let result = "";
  switch(state){
    case "submited":
      result = "status-1";
    break;
    case "to do":
      result = "status-2";
    break;
    default:
      result = "status-1";
    break
  }
  return result;
}

const UserView = () => {
  const params = useParams();
  const token = useSelector(state => state.user.token);
  const rule = useSelector(state => state.user.rule);
  const history = useHistory();
  const [error, setError] = useState(null);


  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if(rule !== "tech team" && rule !== "config"){
        history.push(`/tasks`)
    }
    getUserData()
  }, [])


  const getUserData = () => {

    setIsLoading(true)
    axios.get(`${baseUrl}/users/${params.user_id}`, {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(result => {
      setUserData(result.data)
      setIsLoading(false)
    }).catch(error => {
      setIsLoading(false)
      setError(error.response.data.message)
    })
  }
  return isLoading ? ( // isFetching
      <div className="text-center">
        <TableSpinner animation="border" />
      </div>
    ) : error === null ? (
    <Container>
      <Row>
        <Summary
          labels={[
            {
              id: 0,
              title: "User Id:",
              value: userData.id,
            },
            {
              id: 1,
              title: "Name:",
              value: userData.name,
            },
            {
              id: 2,
              title: "Email:",
              value: userData.email,
            },
            {
              id: 3,
              title: "Role:",
              value: userData.type,
            },
          ]}
          title="User Details"
          isEditable={false}
        />
      </Row>
    </Container>
    ) : (
      <div className='h5 text-white'>{error}</div>
    )
};

export default UserView;


// region STYLES

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;
