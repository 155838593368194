import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import DeleteIcon  from 'mdi-react/DeleteOutlineIcon';
import FiEdit from 'mdi-react/SquareEditOutlineIcon';
import ApiTable from '../../components/table/DataReactTable';
import styled from 'styled-components';
import { Button } from '@/shared/components/Button';
import Modal from './date-filter';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import baseUrl from '../../axiosBase';
import AsyncSelect from 'react-select/async';
// import Select from 'react-select';

const columns = [
    {
        id: 1,
        title: "Id",
        column: "id",
        hasSort: true
    },
    {
        id: 2,
        title: "Title",
        column: "title",
        hasSort: true
    },
    {
        id: 3,
        title: "Date",
        column: "created_at",
        hasSort: true
    },
    {
        id: 4,
        title: "Department",
        column: "department.name",
        hasSort: true
    },
    {
        id: 5,
        title: "Category",
        column: "category.name",
        hasSort: true
    },
    {
        id: 6,
        title: "Status",
        column: "status.name",
        hasSort: true
    },
    {
        id: 7,
        title: "Type",
        column: "type.name",
        hasSort: true
    },
    {
        id: 9,
        title: "Priority",
        column: "priority.name",
        hasSort: true
    },
    // {
    //     id: 7,
    //     title: "Read"
    // },
    {
        id: 8,
        title: "Actions"
    },
]
const rowsPerPage = 15;

const urlReducer = (currentUrl, action) => {
    const baseUri = `${baseUrl}/tasks?page=1&ongoing_tasks`;
    let finalUrl = baseUri;
    switch(action.type){
        case 'change page':
            finalUrl = currentUrl.replace(/page=[0-9]*/gi, `page=${action.page}`) 
        break;
        case 'filter':
            finalUrl = baseUri + action.filterString;
        break;
    }
    return finalUrl;
}

const OpenTasks = () => {
    const token = useSelector(state => state.user.token);
    const rule = useSelector(state => state.user.rule);
    // const counters = useSelector(state => state.sidebar.counters);
    const history = useHistory();
    const [apiUrl, dispatchUrl] = useReducer(urlReducer, `${baseUrl}/tasks?page=1&ongoing_tasks`)
    const [dataRows, setDataRows] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [dataLengthInPages, setDataLengthInPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);

    // filters
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState({value: "all", label: "All Departments"});
    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState({value: "all", label: "All Task Types"});
    const [priorities, setPriorities] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState({value: "all", label: "All Priorities"});
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({value: "all", label: "All Categories"});
    const [statuses, setStatuses] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState({value: "all", label: "All Statuses"});
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({value: "all", label: "All Users"});
    const [dateFilter, setDateFilter] = useState("");
    const [searchByTitle, setSearchByTitle] = useState("");
    const [tasksCount, setTasksCount] = useState(0);
    // const [selectedReadStatus, setSelectedReadStatus] = useState({
    //     value: "all",
    //     label: "All Read Status",
    // });
    // sort
    const [sortBy, setSortBy] = useState({
        column: "created_at",
        type: "desc"
    });
    

    useEffect(() => {
        getTasks();

    }, [
        apiUrl
    ])

    // get filter data
    useEffect(() => {
        axios.get(
            `${baseUrl}/filters-data`,
            {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }
        ).then(result => {
            setDepartments(() => {
                return [
                    {
                        id: "all",
                        name: "All Departments"
                    },
                    ...result.data.departments
                ]
            });
            setTypes(() => {
                return [
                    {
                        id: "all",
                        name: "All Types"
                    },
                    ...result.data.types
                ]
            });
            setPriorities(() => {
                return [
                    {
                        id: "all",
                        name: "All Priorities"
                    },
                    ...result.data.priorities
                ]
            });
            setCategories(() => {
                return [
                    {
                        id: "all",
                        name: "All Categories"
                    },
                    ...result.data.categories
                ]
            });
            setStatuses(() => {
                return [
                    {
                        id: "all",
                        name: "All Statuses"
                    },
                    ...result.data.statuses.filter(item => item.id != 8 && item.id != 9 && item.id != 11)
                ]
            });
            setUsers(() => {
                return [
                    {
                        id: "all",
                        name: "All Users"
                    },
                    ...result.data.users
                ]
            });
        })
    }, [])

    // handle change filter
    useEffect(() => {
        let department = "";
        if(selectedDepartment.value !== "all"){
            department = `&department_id=${selectedDepartment.value}`
        }

        let catecory = "";
        
        if(selectedCategory.value !== "all"){
            catecory = `&category_id=${selectedCategory.value}`
        }

        let status = "";
        
        if(selectedStatus.value !== "all"){
            status = `&status_id=${selectedStatus.value}`
        }
        
        let type = "";
        
        if(selectedType.value !== "all"){
            type = `&type_id=${selectedType.value}`
        }
        let priority = "";
        
        if(selectedPriority.value !== "all"){
            priority = `&priority_id=${selectedPriority.value}`
        }

        let user = "";
        
        if(selectedUser.value !== "all"){
            user = `&created_by=${selectedUser.value}`
        }

        let q = "";
        
        if(searchByTitle !== ""){
            if(searchByTitle[0] == "#"){
                q =`&id=${searchByTitle.replace("#", "")}`
            }else{
                q = `&q=${searchByTitle}`
            }
        }

        // let read = "";
        
        // if(selectedReadStatus.value !== "all"){
        //     read = `&read=${selectedReadStatus.value}`
        // }

        let sort = "";
        
        if(sortBy.column !== ""){
            sort = `&sort=${sortBy.column}&${sortBy.type}`
        }

        dispatchUrl({
            type: 'filter',
            filterString: dateFilter + department + catecory + status + user + type + priority + sort + q //+ read
        })

    }, [
        selectedDepartment,
        selectedCategory,
        selectedStatus,
        selectedUser,
        dateFilter,
        selectedType,
        selectedPriority,
        searchByTitle,
        // selectedReadStatus,
        sortBy,
    ])

    const getTasks = () => {

        try {
            axios.get(apiUrl, {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }).then(result => {
                setDataRows(() => {
                    // const pagedData = result.data.data
                    const rows = result.data.data.map((item) => {
                        const actions = [
                            {
                                id: 0,
                                link: '/task/' + item.id,
                                icon: <EyeOutlineIcon />
                            },
                        ]
    
                        if(rule === "tech team" || rule === "config"){
                            actions.push({
                                id: 1,
                                link: '/task/' + item.id + '/edit',
                                icon: <FiEdit className='ms-2' />
                            })
                            actions.push({
                                id: 2,
                                link: '/task/' + item.id + '/archive',
                                icon: <DeleteIcon className='text-warning ms-1' />
                            })
                        }
                        let typeColor = "";
                        if(item.type?.name == "Upgrade"){
                            typeColor = "text-success fw-bold"
                        }else if(item.type?.name == "Issue"){
                            typeColor = "text-danger fw-bold"
                        }else if(item.type?.name == "Modification"){
                            typeColor = "text-warning fw-bold"
                        }
                        return {
                            id: item.id,
                            actions: actions,
                            sort: sortBy,
                            setSort: setSortBy,
                            columns: [
                                {
                                    id: 1,
                                    data: "#" + item.id
                                },
                                {
                                    id: 2,
                                    data: item.title,
                                    trim: true
                                },
                                {
                                    id: 3,
                                    data: moment(item.created_at).format("DD MMM yyyy"),
                                    class: "text-normal"
                                },
                                {
                                    id: 4,
                                    data: item.department?.name
                                },
                                {
                                    id: 5,
                                    data: item.category?.name
                                },
                                {
                                    id: 6,
                                    data: item.status?.name,
                                    type: "status",
                                    statusId: item.status?.id,
                                    statusColor: handleStatusColors(item.status?.color) || '#007dfa',
                                    statusBgColor: handleStatusColors(item.status?.bg_color) || '#9bccff',
                                },
                                {
                                    id: 7,
                                    data: item.type?.name,
                                    class: typeColor,
                                },
                                {
                                    id: 9,
                                    data: item.priority?.name,
                                },
                                // {
                                //     id: 7,
                                //     data: item.read ? "True" : "False",
                                //     class: item.read ? "text-success fw-bold" : "text-danger fw-bold",
                                // },
                            ],
                            name: item.name
                        }
                    })
                    rows.setSort = setSortBy
                    return rows
                })
                setDataLengthInPages(Math.ceil(result.data.total / rowsPerPage))
                setIsLoading(false)
                setTasksCount(result.data.total)
            })
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }
    const handleChangePage = (val) => {
        setIsLoading(true)
        dispatchUrl({
            type: 'change page',
            page: val
        })
        setCurrentPage(val)
    }

    const handleAddNewTask = () => {
        history.push(`/tasks/create`)
    }
    const handleConfirmFilter = (values) => {
        setIsLoading(true)
        setDateFilter(values);
        setCurrentPage(1)
    }

    // react select async values
    const searchInDepartments = (search, callback) => {
        axios.get(`${baseUrl}/departments?q=${search}`,{
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(result => {
            callback(result.data.data.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        }).catch(error => {
            console.log(error);
            callback(departments.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        })
    }
    const searchInTypes = (search, callback) => {
        axios.get(`${baseUrl}/types?q=${search}`,{
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(result => {
            callback(result.data.data.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        }).catch(error => {
            console.log(error);
            callback(types.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        })
    }
    const searchInPriorities = (search, callback) => {
        axios.get(`${baseUrl}/priorities?q=${search}`,{
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(result => {
            callback(result.data.data.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        }).catch(error => {
            console.log(error);
            callback(priorities.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        })
    }
    const searchInCategories = (search, callback) => {
        axios.get(`${baseUrl}/categories?q=${search}`,{
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(result => {
            callback(result.data.data.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        }).catch(error => {
            console.log(error.response);
            callback(categories.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        })
    }
    const searchInStatuses = (search, callback) => {
        axios.get(`${baseUrl}/statuses?q=${search}`,{
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(result => {
            callback(result.data.data.filter(item => item.id != 8 && item.id != 9 && item.id != 11).map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        }).catch(error => {
            console.log(error.response);
            callback(statuses.filter(item => item.id != 8 && item.id != 9 && item.id != 11).map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        })
    }
    const searchInUsers = (search, callback) => {
        axios.get(`${baseUrl}/users-filter?q=${search}`,{
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(result => {
            callback(result.data.data.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        }).catch(error => {
            console.log(error.response);
            callback(users.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        })
    }
        
    const handleStatusColors = (colorJson) => {
        const cRGBA = JSON.parse(colorJson)?.rgb || null;
        const cText = cRGBA ? `rgba(${cRGBA.r}, ${cRGBA.g}, ${cRGBA.b}, ${cRGBA.a})` : null;

        return cText
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={12}>
                    <div className='d-flex align-items-center mb-4'>
                        <Modal
                            btn="Date"
                            confirm={handleConfirmFilter}
                            color=""
                        />
                        <AsyncSelect
                            value={selectedDepartment}
                            onChange={setSelectedDepartment}
                            className='mx-2'
                            defaultOptions={
                                departments.map(item => {
                                    return {
                                        value: item.id,
                                        label: item.name
                                    }
                                })
                            }
                            loadOptions={searchInDepartments}
                            styles={{
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'grey' : 'red',
                                    width: "12%",
                                    fontSize: ".75rem",
                                    lineHeight: "1.5",
                                    zIndex: "9",
                                }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    zIndex: state.isFocused ? '8' : '8',
                                    borderColor: selectedDepartment.value !== "all" ? 'green' : 'grey',
                                    backgroundColor: selectedDepartment.value !== "all" ? 'rgb(141, 235, 170)' : '#fff',
                                    borderWidth: selectedDepartment.value !== "all" ? '2px' : '1px',
                                })
                            }}
                        />
                        <AsyncSelect
                            value={selectedType}
                            onChange={setSelectedType}
                            className='mx-2'
                            defaultOptions={
                                types.map(item => {
                                    return {
                                        value: item.id,
                                        label: item.name
                                    }
                                })
                            }
                            loadOptions={searchInTypes}
                            styles={{
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'grey' : 'red',
                                    width: "12%",
                                    fontSize: ".75rem",
                                    lineHeight: "1.5",
                                    zIndex: "9",
                                }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    zIndex: state.isFocused ? '8' : '8',
                                    borderColor: selectedType.value !== "all" ? 'green' : 'grey',
                                    backgroundColor: selectedType.value !== "all" ? 'rgb(141, 235, 170)' : '#fff',
                                    borderWidth: selectedType.value !== "all" ? '2px' : '1px',
                                })
                            }}
                        />
                        <AsyncSelect
                            value={selectedPriority}
                            onChange={setSelectedPriority}
                            className='mx-2'
                            defaultOptions={
                                priorities.map(item => {
                                    return {
                                        value: item.id,
                                        label: item.name
                                    }
                                })
                            }
                            loadOptions={searchInPriorities}
                            styles={{
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'grey' : 'red',
                                    width: "12%",
                                    fontSize: ".75rem",
                                    lineHeight: "1.5",
                                    zIndex: "9",
                                }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    zIndex: state.isFocused ? '8' : '8',
                                    borderColor: selectedPriority.value !== "all" ? 'green' : 'grey',
                                    backgroundColor: selectedPriority.value !== "all" ? 'rgb(141, 235, 170)' : '#fff',
                                    borderWidth: selectedPriority.value !== "all" ? '2px' : '1px',
                                })
                            }}
                        />
                        <AsyncSelect
                            value={selectedCategory}
                            onChange={setSelectedCategory}
                            className='mx-2'
                            defaultOptions={
                                categories.map(item => {
                                    return {
                                        value: item.id,
                                        label: item.name
                                    }
                                })
                            }
                            loadOptions={searchInCategories}
                            styles={{
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'grey' : 'red',
                                    width: "12%",
                                    fontSize: ".75rem",
                                    lineHeight: "1.5",
                                    zIndex: "9",
                                }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    zIndex: state.isFocused ? '8' : '8',
                                    borderColor: selectedCategory.value !== "all" ? 'green' : 'grey',
                                    backgroundColor: selectedCategory.value !== "all" ? 'rgb(141, 235, 170)' : '#fff',
                                    borderWidth: selectedCategory.value !== "all" ? '2px' : '1px',
                                })
                            }}
                        />
                        <AsyncSelect
                            value={selectedStatus}
                            onChange={setSelectedStatus}
                            className='mx-2'
                            defaultOptions={
                                statuses.map(item => {
                                    return {
                                        value: item.id,
                                        label: item.name
                                    }
                                })
                            }
                            loadOptions={searchInStatuses}
                            styles={{
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'grey' : 'red',
                                    width: "12%",
                                    fontSize: ".75rem",
                                    lineHeight: "1.5",
                                    zIndex: "9",
                                }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    zIndex: state.isFocused ? '8' : '8',
                                    borderColor: selectedStatus.value !== "all" ? 'green' : 'grey',
                                    backgroundColor: selectedStatus.value !== "all" ? 'rgb(141, 235, 170)' : '#fff',
                                    borderWidth: selectedStatus.value !== "all" ? '2px' : '1px',
                                })
                            }}
                        />
                        {
                            rule === "tech team" || rule === "config" ? (
                                <AsyncSelect
                                    value={selectedUser}
                                    onChange={setSelectedUser}
                                    className='mx-2'
                                    defaultOptions={
                                        users.map(item => {
                                            return {
                                                value: item.id,
                                                label: item.name
                                            }
                                        })
                                    }
                                    loadOptions={searchInUsers}
                                    styles={{
                                        container: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? 'grey' : 'red',
                                            width: "12%",
                                            fontSize: ".75rem",
                                            lineHeight: "1.5",
                                            zIndex: "9",
                                        }),
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            zIndex: state.isFocused ? '8' : '8',
                                            borderColor: selectedUser.value !== "all" ? 'green' : 'grey',
                                            backgroundColor: selectedUser.value !== "all" ? 'rgb(141, 235, 170)' : '#fff',
                                            borderWidth: selectedUser.value !== "all" ? '2px' : '1px',
                                        })
                                    }}
                                />
                            ) : <></>
                        }
                        {/* <Select
                            value={selectedReadStatus}
                            onChange={setSelectedReadStatus}
                            className='mx-2'
                            options={[
                                {
                                    value: "all",
                                    label: "All Read Status",
                                },
                                {
                                    value: "read",
                                    label: "Read Tasks",
                                },
                                {
                                    value: "not-read",
                                    label: "Not Read Tasks",
                                },
                            ]}
                            styles={{
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'grey' : 'red',
                                    width: "12%",
                                    fontSize: ".75rem",
                                    lineHeight: "1.5",
                                    zIndex: "9",
                                }),
                            }}
                        /> */}
                        
                        <ProfileButton onClick={handleAddNewTask}>
                            <span style={{color: "#fff"}}>Add New</span>
                        </ProfileButton>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12} className='position-relative'>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search By Title Or ID (ID ex #1)"
                            value={searchByTitle}
                            onChange={e => setSearchByTitle(e.target.value)}
                        />
                    </div>
                </Col>
                <ApiTable
                    Title={`Open Tasks List (${tasksCount})`}
                    columns={columns}
                    dataRows={dataRows}
                    isLoading={isLoading}
                    // getRowItem= {member => setDeleteTask(member)}
                    currentPage={currentPage}
                    dataLengthInPages={dataLengthInPages}
                    handleChange={handleChangePage}
                />
            </Row>
        </Container>
    );
};

export default OpenTasks;


const ProfileButton = styled(Button)`
    margin-bottom: 0;
    padding: 8px 15px;
    background-color: #1c8551;
    border: none;
    z-index: 99;
    margin-left: auto;
`;