import {
  AUTHENTICATE,
  AUTHENTICATE_ERROR_AUTH,
} from '../actions/authActions';

export const getUserToken = () => {
  const cookieArray = document.cookie.split("; ");
  const tokenName = "token"
  let userToken = null;
  cookieArray.forEach(item => {
    if(item.indexOf(tokenName) == 0){
      userToken = item.substring(tokenName.length + 1)
    }
  })
  return userToken;
}
export const getRuleToken = () => {
  const cookieArray = document.cookie.split("; ");
  const rule = "rule"
  let userRule = null;
  cookieArray.forEach(item => {
    if(item.indexOf(rule) == 0){
      userRule = item.substring(rule.length + 1)
    }
  })
  return userRule;
}
const initialState = {
  fullName: 'Roman Johanson',
  rule: getRuleToken(),
  token: getUserToken(),
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        fullName: action.user.name,
        rule: action.user.rule,
        token: action.user.token
      };
    case AUTHENTICATE_ERROR_AUTH:
      return { error: action.error };
    default:
      return state;
  }
};

export default authReducer;
