import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import FiEdit from 'mdi-react/SquareEditOutlineIcon';
import ApiTable from '../../components/table/DataReactTable';
import styled from 'styled-components';
import { Button } from '@/shared/components/Button';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import base from '../../axiosBase';
// import moment from 'moment';
import baseUrl from '../../axiosBase';

const columns = [
    {
        id: 1,
        title: "Id"
    },
    {
        id: 2,
        title: "User Name"
    },
    {
        id: 3,
        title: "Email"
    },
    {
        id: 4,
        title: "Role"
    },
    {
        id: 7,
        title: "Actions"
    },
]
const rowsPerPage = 20;

const urlReducer = (currentUrl, action) => {
    const baseUri = `${baseUrl}/users?page=1`;
    let finalUrl = baseUri;
    switch(action.type){
        case 'change page':
            finalUrl = currentUrl.replace(/page=[0-9]*]/gi, `page=${action.page}`) 
        break;
        case 'filter':
            finalUrl = base + action.filterString;
        break;
    }
    return finalUrl;
}

const UsersList = () => {
    const token = useSelector(state => state.user.token);
    const rule = useSelector(state => state.user.rule);
    const history = useHistory();
    const [apiUrl, dispatchUrl] = useReducer(urlReducer, `${baseUrl}/users?page=1`)
    const [dataRows, setDataRows] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [dataLengthInPages, setDataLengthInPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if(rule !== "tech team" && rule !== "config"){
            history.push(`/tasks`)
        }
    }, [])
    useEffect(() => {
        getTasks();

    }, [
        apiUrl
    ])

    const getTasks = () => {

        try {
            axios.get(apiUrl, {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }).then(result => {
                setDataRows(() => {
                    // const pagedData = result.data.data
                    
                    const rows = result.data.data.map((item) => {
                        return {
                            id: item.id,
                            actions: [
                                {
                                    id: 0,
                                    link: '/user/' + item.id,
                                    icon: <EyeOutlineIcon />
                                },
                                {
                                    id: 1,
                                    link: '/user/' + item.id + '/edit',
                                    icon: <FiEdit className='ms-2' />
                                },
                                // {
                                //     id: 2,
                                //     link: '#',
                                //     type: 'delete',
                                //     icon: (
                                //         <Modal
                                //           color="danger"
                                //           title="Stop!"
                                //           btn={<DeleteForeverIcon className='ms-3 text-danger'/>}
                                //           confirm={handleDeleteTask}
                                //           type="icon"
                                //           message={'Are You Sure You Want To Delete Task ' + deleteTask.name}
                                //         />
                                //     )
                                // },
                            ],
                            columns: [
                                {
                                    id: 1,
                                    data: "#" + item.id
                                },
                                {
                                    id: 2,
                                    data: item.name
                                },
                                {
                                    id: 3,
                                    data: item.email,
                                    class: "text-normal"
                                },
                                {
                                    id: 4,
                                    data: item.type
                                },
                            ],
                            name: item.name
                        }
                    })
                    return rows
                })
                setDataLengthInPages(Math.ceil(result.data.data.length / rowsPerPage))
                setIsLoading(false)
            })
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }
    const handleChangePage = (val) => {
        setIsLoading(true)
        dispatchUrl({
            type: 'change page',
            page: val
        })
        setCurrentPage(val)
    }

    const handleAddNewTask = () => {
        history.push(`/users/create`)
    }
    
    return (
        <Container>
            <Row>
                <Col md={12} className='position-relative'>
                    <ProfileButton onClick={handleAddNewTask}>
                        <span style={{color: "#fff"}}>Add New</span>
                    </ProfileButton>
                </Col>
                <ApiTable
                    Title="Users List"
                    columns={columns}
                    dataRows={dataRows}
                    isLoading={isLoading}
                    // getRowItem= {member => setDeleteTask(member)}
                    currentPage={currentPage}
                    dataLengthInPages={dataLengthInPages}
                    handleChange={handleChangePage}
                />
            </Row>
        </Container>
    );
};

export default UsersList;


const ProfileButton = styled(Button)`
    margin-bottom: 0;
    padding: 8px 15px;
    background-color: #1c8551;
    border: none;
    position: absolute;
    right: 35px;
    top: 10px;
    z-index: 99;
`;