import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
// import ProfileMain from '../../../containers/Account/Profile/components/ProfileMain';
import { colorAccent } from '@/utils/palette';
import styled from 'styled-components';
import {
  Card, CardBody, CardTitleWrap, CardTitle
} from '@/shared/components/Card';
import { useHistory } from 'react-router-dom';
import Form from '../../../components/form/Form';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import baseUrl from '../../../axiosBase';


const TypeCreate = () => {
  const token = useSelector(state => state.user.token);
  const rule = useSelector(state => state.user.rule);
  const history = useHistory();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [error, setError] = useState(null);

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if(rule !== "tech team" && rule !== "config"){
        history.push(`/tasks`)
    }
    setTimeout(() => setIsLoading(false), 500)
  }, [])

  const submitHandler = async values => {
    
    setIsLoadingSubmit(true)
    setError(null)
    if(
      !values.name
    ){
      setIsLoadingSubmit(false)
      setError("All fields are required")
      return;
    }
    if(
      isLoadingSubmit
    ){
      setIsLoadingSubmit(false)
      setError("Can't Submit Untill End")
      return;
    }

    try{

      axios.post(`${baseUrl}/types`, {
        "name" : values.name,
      }, {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then(result => {
        console.log(result.status)
        setIsLoadingSubmit(false)
        history.push(`/types`)
      })

    }catch(error) {
      setIsLoadingSubmit(false)
      console.log(error)
    }
  }

  const cancleHandler = () => {
    history.push(`/types`)
  }
  return (
    <Container>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <CardTitle>Add New Type</CardTitle>
              </CardTitleWrap>
              <Alert
                variant="danger"
                show={!!error}
              >
                {error}
              </Alert>
              {
                isLoadingSubmit && ( // isFetching
                  <SpinnerContainer>
                    <TableSpinner animation="border" />
                  </SpinnerContainer>
                )
              }
              {
                isLoading ? ( // isFetching
                  <div className="text-center">
                    <TableSpinner animation="border" />
                  </div>
                ) : (
                  <Form
                    isHorizontal={true}
                    submitHandler={submitHandler}
                    cancleHandler={cancleHandler}
                    initValue={{}}
                    inputs={[
                      {
                        id: 1,
                        label: "Name",
                        inputName: "name",
                        type: "text",
                        placeholder: "Enter Type Title"
                      },
                    ]}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default TypeCreate;


// region STYLES

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;

const SpinnerContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #33333333;
  display: flex;
  justify-content: center;
  align-items: center;
`;