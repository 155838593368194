import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import baseUrl from '../../axiosBase';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StatusCard from './StatusCard';
import UpComingTable from './UpComingTable';
import OpenTable from './OpenTable';


const HomePage = () => {
    const token = useSelector(state => state.user.token);
    const rule = useSelector(state => state.user.rule);
    const history = useHistory();

    const [totalTasks, setTotalTasks] = useState(0);
    const [openTasks, setOpenTasks] = useState(0);
    const [closedTasks, setClosedTasks] = useState(0);
    const [upcomingTasks, setUpcomingTasks] = useState(0);

    useEffect(() => {
        if(rule !== "tech team" && rule !== "config"){
            history.push(`/tasks`)
        }
        axios.get(
            `${baseUrl}/tasks?homepage`,
            {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }
        ).then(result => {
            setTotalTasks(result.data.tasks_count)
            setOpenTasks(result.data.open_tasks_count)
            setClosedTasks(result.data.close_tasks_count)
            setUpcomingTasks(result.data.upcoming_tasks_count)
        });
    }, []);
    
    return (
        <Container>
            <Row>
                <StatusCard
                    title="Total Tasks"
                    status={totalTasks}
                    color="blue"
                    hasPercentBar={false}
                    percent={100}
                />
                <StatusCard
                    title="Open Tasks"
                    status={openTasks}
                    color="turquoise"
                    percent={totalTasks === 0 ? 0 : openTasks / totalTasks * 100}
                />
                <StatusCard
                    title="Closed Tasks"
                    status={closedTasks}
                    color="pink"
                    percent={totalTasks === 0 ? 0 : closedTasks / totalTasks * 100}
                />
                <StatusCard
                    title="Upcoming Tasks"
                    status={upcomingTasks}
                    color="lime"
                    percent={totalTasks === 0 ? 0 : upcomingTasks / totalTasks * 100}
                />
            </Row>
            <UpComingTable />
            <OpenTable />
        </Container>
    );
};

export default HomePage;