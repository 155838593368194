import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
// import ProfileMain from '../../../containers/Account/Profile/components/ProfileMain';
import { colorAccent } from '@/utils/palette';
import styled from 'styled-components';
import {
  Card, CardBody, CardTitleWrap, CardTitle
} from '@/shared/components/Card';
import { useHistory } from 'react-router-dom';
import Form from '../../../components/form/Form';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import baseUrl from '../../../axiosBase';


const StatusCreate = () => {
  const token = useSelector(state => state.user.token);
  const rule = useSelector(state => state.user.rule);
  const history = useHistory();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState("Test");
  const [textColor, setTextColor] = useState({
    hax: '#007dfa',
    rgb: {
      r: 0, g: 125, b: 250, a: 1,
    }
  });
  const [backgroundColor, setBackgroundColor] = useState({
    hax: '#9bccff',
    rgb: {
      r: 155, g: 204, b: 255, a: 1,
    }
  });

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if(rule !== "tech team" && rule !== "config"){
        history.push(`/tasks`)
    }
    setTimeout(() => setIsLoading(false), 500)
  }, [])

  const submitHandler = async values => {
    
    setIsLoadingSubmit(true)
    setError(null)
    if(
      !values.name ||
      !textColor ||
      !backgroundColor
    ){
      setIsLoadingSubmit(false)
      setError("All fields are required")
      return;
    }
    if(
      isLoadingSubmit
    ){
      setIsLoadingSubmit(false)
      setError("Can't Submit Untill End")
      return;
    }

    try{

      axios.post(`${baseUrl}/statuses`, {
        "name" : values.name,
        "color": JSON.stringify(textColor),
        "bg_color": JSON.stringify(backgroundColor),
      }, {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then(result => {
        console.log(result.status)
        setIsLoadingSubmit(false)
        history.push(`/statuses`)
      })

    }catch(error) {
      setIsLoadingSubmit(false)
      console.log(error)
    }
  }

  const cancleHandler = () => {
    history.push(`/statuses`)
  }
  
  const handleChangeName = (e) => {
    setName(e.target.value);
  }
  const handleChangeTextColor = (e) => {
    setTextColor({
      hax: e.hex,
      rgb: e.rgb
    })
  }
  const handleChangeBackgroundColor = (e) => {
    setBackgroundColor({
      hax: e.hex,
      rgb: e.rgb
    })
  }
  return (
    <Container>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <CardTitleWrap className="d-flex justify-content-between align-items-center">
                <CardTitle>Add New Status</CardTitle>
                <ExampleStatus style={{
                  color: `rgba(${textColor.rgb.r}, ${textColor.rgb.g}, ${textColor.rgb.b}, ${textColor.rgb.a})`,
                  backgroundColor: `rgba(${backgroundColor.rgb.r}, ${backgroundColor.rgb.g}, ${backgroundColor.rgb.b}, ${backgroundColor.rgb.a})`,
                  textTransform: "none",
                }}>{name || "Test"}</ExampleStatus>
              </CardTitleWrap>
              <Alert
                variant="danger"
                show={!!error}
              >
                {error}
              </Alert>
              {
                isLoadingSubmit && ( // isFetching
                  <SpinnerContainer>
                    <TableSpinner animation="border" />
                  </SpinnerContainer>
                )
              }
              {
                isLoading ? ( // isFetching
                  <div className="text-center">
                    <TableSpinner animation="border" />
                  </div>
                ) : (
                  <Form
                    isHorizontal={true}
                    submitHandler={submitHandler}
                    cancleHandler={cancleHandler}
                    initValue={{}}
                    inputs={[
                      {
                        id: 1,
                        label: "Name",
                        inputName: "name",
                        type: "text",
                        handleChange: handleChangeName,
                        placeholder: "Enter Status Title"
                      },
                      {
                        id: 2,
                        label: "Text Color",
                        inputName: "textcolor",
                        type: "color-picker",
                        placeholder: "Choose Text Color",
                        handleChange: handleChangeTextColor,
                        defaultValue: textColor,
                      },
                      {
                        id: 3,
                        label: "Background Color",
                        inputName: "backgroundcolor",
                        type: "color-picker",
                        placeholder: "Choose Text Color",
                        handleChange: handleChangeBackgroundColor,
                        defaultValue: backgroundColor,
                      },
                    ]}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default StatusCreate;


// region STYLES

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;

const SpinnerContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #33333333;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ExampleStatus = styled.span`
  padding: 0.25rem .5rem;
  border-radius: 5px;
`;