import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-bootstrap/Pagination';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import styled from 'styled-components';
import {
  colorAccent,
  colorAdditional,
  // colorBackground,
  colorFieldsBorder,
  colorIcon,
} from '@/utils/palette';
import {
  marginRight,
  // marginLeft,
} from '@/utils/directions';

const PaginationComponent = ({ currentPage, dataLengthInPages, handleChange }) => {
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    setLastPage(dataLengthInPages)
  }, [dataLengthInPages])

  const setPage = (page) => {
    if (page < 1 || page > lastPage) {
      return;
    }
    handleChange(page)
  };

  let items = [];

  if (currentPage > 4 && currentPage < lastPage - 3) {
    items = [
      <Pagination.Item key="1" onClick={() => setPage(1)}>{1}</Pagination.Item>,
      <Pagination.Ellipsis key="ellipsis_before"/>,
      <Pagination.Item key="c-2" onClick={() => setPage(currentPage - 2)}>{currentPage - 2}</Pagination.Item>,
      <Pagination.Item key="c-1" onClick={() => setPage(currentPage - 1)}>{currentPage - 1}</Pagination.Item>,
      <Pagination.Item key="c" onClick={() => {}} active={true}>{currentPage}</Pagination.Item>,
      <Pagination.Item key="c+1" onClick={() => setPage(currentPage + 1)}>{currentPage + 1}</Pagination.Item>,
      <Pagination.Item key="c+2" onClick={() => setPage(currentPage + 2)}>{currentPage + 2}</Pagination.Item>,
      <Pagination.Ellipsis key="ellipsis_after"/>,
      <Pagination.Item key="last" onClick={() => setPage(lastPage)}>{lastPage}</Pagination.Item>
    ]
  }else if(currentPage <= 4){
    items = [
      <Pagination.Item key="1" onClick={() => currentPage === 1 ? null : setPage(1)} active={currentPage === 1}>{1}</Pagination.Item>,
      lastPage >= 2 && <Pagination.Item key="2" onClick={() => currentPage === 2 ? null : setPage(2)} active={currentPage === 2}>{2}</Pagination.Item>,
      lastPage >= 3 && <Pagination.Item key="3" onClick={() => currentPage === 3 ? null : setPage(3)} active={currentPage === 3}>{3}</Pagination.Item>,
      lastPage >= 4 && <Pagination.Item key="4" onClick={() => currentPage === 4 ? null : setPage(4)} active={currentPage === 4}>{4}</Pagination.Item>,
      lastPage >= 5 && <Pagination.Item key="5" onClick={() => currentPage === 5 ? null : setPage(5)}>{5}</Pagination.Item>,
      currentPage === 4 && lastPage >= 6 && <Pagination.Item key="6" onClick={() => currentPage === 6 ? null : setPage(6)}>{6}</Pagination.Item>,
      lastPage > 7 && <Pagination.Ellipsis key="ellipsis_after"/>,
      lastPage > 7 && <Pagination.Item onClick={() => setPage(lastPage)}>{lastPage}</Pagination.Item>
    ]
  }else{
    items = [
      (lastPage <= 5 || currentPage >= 5) && <Pagination.Item key="1" onClick={() => currentPage === 1 ? null : setPage(1)} active={currentPage === 1}>{1}</Pagination.Item>,
      (lastPage > 5 || currentPage < 5) && <Pagination.Ellipsis key="ellipsis_before"/>,
      currentPage === 5 && lastPage === 8 && <Pagination.Item key="l-5" onClick={() => setPage(lastPage - 5)}>{lastPage - 5}</Pagination.Item>,
      currentPage <= lastPage - 2 && <Pagination.Item key="l-4" onClick={() => setPage(lastPage - 4)}>{lastPage - 4}</Pagination.Item>,
      <Pagination.Item key="l-3" onClick={() => currentPage === lastPage - 3 ? null : setPage(lastPage - 3)} active={currentPage === lastPage - 3}>{lastPage - 3}</Pagination.Item>,
      <Pagination.Item key="l-2" onClick={() => currentPage === lastPage - 2 ? null : setPage(lastPage - 2)} active={currentPage === lastPage - 2}>{lastPage - 2}</Pagination.Item>,
      <Pagination.Item key="l-1" onClick={() => currentPage === lastPage - 1 ? null : setPage(lastPage - 1)} active={currentPage === lastPage - 1}>{lastPage - 1}</Pagination.Item>,
      <Pagination.Item key="l" onClick={() => currentPage === lastPage ? null : setPage(lastPage)} active={currentPage === lastPage}>{lastPage}</Pagination.Item>
    ]
  }

  return (
    <PaginationWrap>
      <Pagination>
        <Pagination.Prev
          disabled={currentPage === 1}
          className={`pagination__item--arrow ${currentPage === 1 && 'disabled'}`}
          onClick={() => currentPage !== 1 && setPage(currentPage - 1)}
        >
          <ChevronLeftIcon />
        </Pagination.Prev>
        {items}
        <Pagination.Next
          disabled={currentPage === 5}
          className={`pagination__item--arrow ${lastPage === currentPage && 'disabled'}`}
          onClick={() => lastPage !== currentPage && setPage(currentPage + 1)}
        >
          <ChevronRightIcon />
        </Pagination.Next>
      </Pagination>
    </PaginationWrap>
  );
};


export default PaginationComponent;


PaginationComponent.propTypes = {
  currentPage: PropTypes.number.isRequired,
  dataLengthInPages: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export const PaginationWrap = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    
    & > * {
      margin: 10px 0;
    }
  }

  .page-item {
    ${marginRight}: 8px;

    .page-link {
      background: transparent;
      border: none;
      color: ${colorAdditional};
      height: 28px;
      line-height: 18px;
      font-size: 10px;
      padding: 5px;
      text-align: center;
      min-width: 28px;
      transition: all 0.3s;

      &:hover {
        color: ${colorAccent};
        background: transparent;
      }

      &:focus, &:active {
        box-shadow: none;
      }

      svg {
        width: 13px;
        height: 13px;
        fill: ${colorAdditional};
        transition: 0.3s;
        position: absolute;
        top: 7px;
        left: 8px;
      }
    }

    &.pagination__item--arrow .page-link,
    & .page-link{
      border-radius: 50%;
      width: 28px;
      height: 28px;
      background: ${colorFieldsBorder};

      &:hover {
        background: ${colorAccent};
        color: white;

        svg {
          fill: white;
        }
      }
    }

    &.pagination__item--arrow.disabled .page-link {
      cursor: default;
      opacity: 0.4;
      background: ${colorFieldsBorder};

      svg {
        fill: ${colorAdditional};
      }
    }

    &.active .page-link {
      background-color: ${colorAccent};
      font-weight: bold;
      color: #fff;
    }

    &.disabled .page-link svg {
      fill: ${colorIcon};
    }
  }
`;