import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { lighten } from 'polished';
import {
  colorAccent,
  colorHover,
  colorText,
  sidebarColor,
  colorGray,
} from '@/utils/palette';
import { 
  left,
  marginRight,
} from '@/utils/directions';
import FileTreeIcon from 'mdi-react/FileTreeIcon';
import FormatListBulletedTypeIcon  from 'mdi-react/FormatListBulletedTypeIcon';
import { useSelector } from 'react-redux';

const SidebarLink = ({
  title, icon, route, onClick, counter = undefined
}) => {
  const sidebarCollapse = useSelector(state => state.sidebar.collapse);
  return (
  <li>
    <SidebarNavLink
      to={route}
      exact
      onClick={onClick}
      activeClassName="active"
      className={title === "Test Mode" ? "test_mode" : ""}
    >
      {
        icon ?
          icon === 'tasks' ?
            <FileTreeIconStyled className={sidebarCollapse ? "iconCollapse" : ""}/>
          : icon === 'type' ?
              <FormatListBulletedTypeIconStyled className={sidebarCollapse ? "iconCollapse" : ""}/>
            : <SidebarLinkIcon className={`lnr lnr-${icon}`} />
        : ''
      }
      <SidebarLinkTitle>
        {title} {counter != undefined && <>({counter})</>}
      </SidebarLinkTitle>
    </SidebarNavLink>
  </li>
  )
};

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  route: PropTypes.string,
  onClick: PropTypes.func,
  counter: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.undefined,
    PropTypes.string
  ])
};

SidebarLink.defaultProps = {
  icon: '',
  route: '/',
  onClick: () => {},
};

export default SidebarLink;

// region STYLES

export const SidebarNavLink = styled(NavLink)`
  height: 36px;
  width: 240px;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 11px 20px;
  overflow: hidden;
  background: transparent;
  border: none;
  color: ${colorText};
  text-align: ${left};
  font-size: 14px;

  &.active {
    background: ${sidebarColor};

    &.test_mode{
      background: transparent;
      color: red;
      &::before{
        opacity: 0;
      }
    }
    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    background: ${colorAccent};
    opacity: 0;
    transition: all 0.3s;
    ${left}: 0;
  }

  &:hover {
    text-decoration: none;
    background-color: ${colorHover};
    color: ${colorText};

    &:before {
      opacity: 1;
    }
  }

  @media screen and (min-width: 576px) {
    width: 100%;

    span {
      position: relative;
      animation: none;
      ${left}: 0;
    }
  }
`;

export const SidebarLinkTitle = styled.span`
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const SidebarLinkIcon = styled.span`
  font-size: 14px;
  line-height: 13px;
  color: ${lighten(0.25, colorGray)};
  ${marginRight}: 10px;
`;
export const FileTreeIconStyled = styled(FileTreeIcon)`
  font-size: 14px;
  line-height: 13px;
  width: 14px;
  height: 14px;
  color: ${lighten(0.25, colorGray)};
  ${marginRight}: 10px;
  &.iconCollapse{
    ${marginRight}: 0;
  }
`;
export const FormatListBulletedTypeIconStyled = styled(FormatListBulletedTypeIcon)`
  font-size: 14px;
  line-height: 13px;
  width: 14px;
  height: 14px;
  color: ${lighten(0.25, colorGray)};
  ${marginRight}: 10px;
  &.iconCollapse{
    ${marginRight}: 0;
  }
`;

// endregion
