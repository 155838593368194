import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  Card, CardBody
} from '@/shared/components/Card';
// import Carousel from '@/shared/components/carousel/CarouselSingle';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


const BasicCarouselWithoutCaption = ({images, voices, videos}) => {
  // const { t } = useTranslation('common');

  return (
    <>
      {
        (images.length > 0 || videos.length > 0) && (
          <Col md="8">
            <Row>
              {images.map(item => (
                <Col md="4" key={item.id}>
                  <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                      <div
                        style={{
                          height: 175,
                          width: 175,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden"
                        }}
                      >
                        <Zoom>
                          <img src={item.src} alt={`slide_${item.src}`} />
                        </Zoom>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
              {videos.map(item => (
                <Col md="4" key={item.id}>
                  <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                      <video className='w-100' controls>
                        <source src={item.src} />
                      </video>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        )
      }
      {
        voices.length > 0 && (
          <Col md="4">
            <Card>
              <CardBody>
                
                {voices.map(item => (
                  <div key={item.id} className='audio'>
                    <audio controls>
                      <source src={item.src} type="audio/mpeg" />
                    </audio>
                  </div>
                ))}
              </CardBody>
            </Card>
          </Col>
        )
      }
    </>
  );
};
BasicCarouselWithoutCaption.propTypes = {
  images: PropTypes.array,
  voices: PropTypes.array,
  videos: PropTypes.array,
};
export default BasicCarouselWithoutCaption;
