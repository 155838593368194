import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
// import ProfileMain from '../../../containers/Account/Profile/components/ProfileMain';
import { colorAccent } from '@/utils/palette';
import styled from 'styled-components';
import {
  Card, CardBody, CardTitleWrap, CardTitle
} from '@/shared/components/Card';
import { useHistory, useParams } from 'react-router-dom';
import Form from '../../../components/form/Form';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import baseUrl from '../../../axiosBase';
import AttachmentList from './attachmentslist';
import { changeMenuCounter } from '../../../redux/actions/sidebarActions';


const TaskEdit = () => {
  const dispatch = useDispatch();

  const token = useSelector(state => state.user.token);
  const rule = useSelector(state => state.user.rule);
  const history = useHistory();
  const params = useParams();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [departmentSelect, setDepartmentSelect] = useState(1);
  const [typeSelect, setTypeSelect] = useState(1);
  const [prioritySelect, setPrioritySelect] = useState(1);
  const [categorySelect, setCategorySelect] = useState(1);
  const [statusSelect, setStatusSelect] = useState(1);
  const [attachments, setAttachments] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [taskData, setTaskData] = useState({});

  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    if(rule !== "tech team" && rule !== "config"){
        history.push(`/tasks`)
    }
    getTaskData()
  }, [])


  const getTaskData = () => {
    setIsLoading(true)
    axios.all([
      axios.get(
        `${baseUrl}/tasks/${params.task_id}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ),
      axios.get(
        `${baseUrl}/departments`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ),
      axios.get(
        `${baseUrl}/categories`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ),
      axios.get(
        `${baseUrl}/statuses`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ),
      axios.get(
        `${baseUrl}/types`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ),
      axios.get(
        `${baseUrl}/priorities`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ),
    ]).then(result => {
      setTaskData(result[0].data.data)
      setDepartmentSelect(result[0].data.data.department.id)
      setTypeSelect(result[0].data.data.type.id)
      setPrioritySelect(result[0].data.data.priority.id)
      setCategorySelect(result[0].data.data.category.id)
      setStatusSelect(result[0].data.data.status.id)
      setDepartmentList(result[1].data.data)
      setCategoryList(result[2].data.data)
      setStatusList(result[3].data.data)
      setTypeList(result[4].data.data)
      setPriorityList(result[5].data.data)
      setIsLoading(false)
    })
  }

  const submitHandler = async values => {
    
    setIsLoadingSubmit(true)
    setError(null)
    if(
      !values.title ||
      !values.description_en
    ){
      setIsLoadingSubmit(false)
      setError("All fields are required")
      return;
    }
    if(
      isLoadingSubmit
    ){
      setIsLoadingSubmit(false)
      setError("Can't Submit Untill End")
      return;
    }
    if(values.title.length > 255){
      setIsLoadingSubmit(false)
      setError("Title is too long")
      return;
    }
    const formdata = new FormData();
    formdata.append('_method', 'post');
    formdata.append("title", values.title);
    formdata.append("description_admin", values.description_admin);
    formdata.append("description_en", values.description_en);
    formdata.append("description_ar", values.description_ar);
    // const files = [];
    attachments.forEach(attachment => {
      const fileInput = document.getElementById(attachment.inputName);
      console.log(fileInput.files[0])
      if(fileInput.files[0]){
        formdata.append("attachments[]", fileInput.files[0]);
      }
    })
    
    formdata.append("department_id", departmentSelect);
    formdata.append("category_id", categorySelect);
    formdata.append("status_id", statusSelect);
    formdata.append("type_id", typeSelect);
    formdata.append("priority_id", prioritySelect);
    console.log(prioritySelect)
    try{

      axios.post(`${baseUrl}/tasks/${params.task_id}`, formdata, {
        headers: {
          'accept': 'application/json',
          'content-type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      }).then(result => {
        dispatch(
          changeMenuCounter({
            tasks: result.data.counters.open_tasks,
            open: result.data.counters.ongoing_tasks,
            upcoming: result.data.counters.new,
            archived: result.data.counters.archived,
            postponed: result.data.counters.postponed,
            approval: result.data.counters.approval,
            approved: result.data.counters.approved,
            alert: result.data.counters.alert,
          })
        )
        setIsLoadingSubmit(false)
        history.push(`/tasks`)
      }).catch(error => {
        setIsLoadingSubmit(false)
        console.log(error.response)
      })

    }catch(error) {
      setIsLoadingSubmit(false)
      console.log(error.responce)
    }
  }

  const cancleHandler = () => {
    history.push(`/tasks`)
  }
  const addAttachmentHandler = () => {
    let manuallyOpened = false;
    setAttachments(prev => {
      if (prev.length === 0){
        return [
          ...prev,
          {
            id: "4_" + prev.length,
            type: "file",
            label: "Attachment " + (prev.length + 1),
            inputName: "attachment_" + prev.length
          }
        ]
      }else if(prev.length > 0){
        const fileInput = document.getElementById(prev[prev.length - 1].inputName);
        if (fileInput.files.length === 0 && !manuallyOpened) {
          manuallyOpened = true;
          fileInput.click();
          return [...prev];
        } else if(fileInput.files.length > 0 && !manuallyOpened) {
          // No file selected
          return [
            ...prev,
            {
              id: "4_" + prev.length,
              type: "file",
              label: "Attachment " + (prev.length + 1),
              inputName: "attachment_" + prev.length
            }
          ]
        }else{
          return [...prev];
        }
      }
      
    })
  }
  const removeAttachmentHandler = () => {
    setAttachments(prev => {
      return prev.filter(item => item.id !== prev[prev.length - 1].id);
    })
  }
  return (
    <Container>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <CardTitle>Add New Task</CardTitle>
              </CardTitleWrap>
              <Alert
                variant="danger"
                show={!!error}
              >
                {error}
              </Alert>
              {
                isLoadingSubmit && ( // isFetching
                  <SpinnerContainer>
                    <TableSpinner animation="border" />
                  </SpinnerContainer>
                )
              }
              {
                isLoading ? ( // isFetching
                  <div className="text-center">
                    <TableSpinner animation="border" />
                  </div>
                ) : (
                  <Form
                    isHorizontal={true}
                    submitHandler={submitHandler}
                    cancleHandler={cancleHandler}
                    initValue={{
                      title: taskData.title || "",
                      description_admin: taskData.description_admin,
                      description_en: taskData.description_en,
                      description_ar: taskData.description_ar,
                    }}
                    inputs={[
                      {
                        id: 0,
                        label: "Title",
                        inputName: "title",
                        type: "text",
                        placeholder: "Enter Task Title"
                      },
                      {
                        id: "1_1",
                        label: "Description [Admin]",
                        inputName: "description_admin",
                        type: "textarea",
                        // value: taskData.description_ar,
                        placeholder: "Enter Task Admin Description"
                      },
                      {
                        id: 1,
                        label: "Description [EN]",
                        inputName: "description_en",
                        type: "textarea",
                        // value: taskData.description_ar,
                        placeholder: "Enter Task Description"
                      },
                      {
                        id: "1_2",
                        label: "Description [AR]",
                        inputName: "description_ar",
                        type: "textarea",
                        placeholder: "Enter Task Description In Arabic",
                        dir: "rtl"
                      },
                      {
                        id: 2,
                        label: "Department",
                        inputName: "department",
                        type: "select",
                        onChangeHandler: e => setDepartmentSelect(e.value),
                        options: departmentList.map(item => {
                          return {
                            id: item.id,
                            selected: item.id === taskData.department?.id,
                            value: item.id,
                            label: item.name,
                          }
                        })
                      },
                      {
                        id: 21,
                        label: "Type",
                        inputName: "type",
                        type: "select",
                        onChangeHandler: e => setTypeSelect(e.value),
                        options: typeList.map(item => {
                          return {
                            id: item.id,
                            selected: item.id === taskData.type?.id,
                            value: item.id,
                            label: item.name,
                          }
                        })
                      },
                      {
                        id: 22,
                        label: "Priority",
                        inputName: "priority",
                        type: "select",
                        onChangeHandler: e => setPrioritySelect(e.value),
                        options: priorityList.map(item => {
                          return {
                            id: item.id,
                            selected: item.id === taskData.priority?.id,
                            value: item.id,
                            label: item.name,
                          }
                        })
                      },
                      {
                        id: 3,
                        label: "Category",
                        inputName: "category",
                        type: "select",
                        onChangeHandler: e => setCategorySelect(e.value),
                        options: categoryList.map(item => {
                          return {
                            id: item.id,
                            selected: item.id === taskData.category?.id,
                            value: item.id,
                            label: item.name,
                          }
                        })
                      },
                      {
                        id: 4,
                        label: "Status",
                        inputName: "status",
                        type: "select",
                        onChangeHandler: e => setStatusSelect(e.value),
                        options: statusList.map(item => {
                          return {
                            id: item.id,
                            selected: item.id === taskData.status?.id,
                            value: item.id,
                            label: item.name,
                          }
                        })
                      },
                      ...attachments,
                      {
                        id: 5,
                        type: "buttons",
                        value1: "Add Attatchment",
                        value2: "Remove Attatchment",
                        func1: addAttachmentHandler,
                        func2: removeAttachmentHandler,
                        hasAttach: attachments.length > 0
                      },
                    ]}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
      {
        isLoading ? ( // isFetching
          <div className="text-center">
            <TableSpinner animation="border" />
          </div>
        ) : (
          <AttachmentList
            attachmentList={taskData.attachments || []}
            getTaskData={getTaskData}
          />
        )
      }
    </Container>
  )
};

export default TaskEdit;


// region STYLES

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;

const SpinnerContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #33333333;
  display: flex;
  justify-content: center;
  align-items: center;
`;