import React from 'react';
import PropTypes from 'prop-types';
// import EmailIcon from 'mdi-react/EmailIcon';
// import AccountSearchIcon from 'mdi-react/AccountSearchIcon';
import { Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { SelectField } from '@/shared/components/form/Select';
import ChromeColorPickerField from '@/shared/components/form/color-pickers/ChromeColorPicker';
// import {
//   colorText
// } from '@/utils/palette';
// import { FileInputField } from '@/shared/components/form/FileInput';
// import PasswordField from '@/shared/components/form/Password';
// import showResults from '@/utils/showResults';
import {
  Card, CardBody,
} from '@/shared/components/Card';
// import renderSelectField from '@/shared/components/form/Select';
import {
  // FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  // FormGroupIcon,
  FormGroupLabel,
  // FormGroupDescription,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
// import styled from 'styled-components';
import Select from 'react-select';

const selectOptions = [
  { value: 'one', label: 'One' },
  { value: 'two', label: 'Two' },
];

const Form = ({ isHorizontal, initValue, inputs, submitHandler, cancleHandler = () => { } }) => {
  const {
    handleSubmit,
    register,
    control,
  } = useForm({ defaultValues: initValue });

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <FormContainer enctype="application/x-www-form-urlencoded" horizontal={isHorizontal} onSubmit={handleSubmit(submitHandler)}>
            {
              inputs.map(
              input => input.type !== "dropdown" &&
              input.type !== "file" &&
              input.type !== "checkbox" &&
              input.type !== "select" &&
              input.type !== "buttons" &&
              input.type !== "color-picker" &&
              input.type !== "textarea" ?
                (
                  <FormGroup key={input.id}>
                    <FormGroupLabel>{input.label}</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name={input.inputName}
                        type={input.type}
                        placeholder={input.placeholder || ""}
                        {...register(input.inputName)}
                        autoComplete="new-password"
                        onChange={input.handleChange}
                      />
                    </FormGroupField>
                  </FormGroup>
                ) : input.type === "dropdown" ? (
                  <FormGroup key={input.id}>
                    <FormGroupLabel>{input.label}</FormGroupLabel>
                    <FormGroupField>
                      <Controller
                        name={input.inputName}
                        render={({ field }) => <SelectField {...field} options={selectOptions} />}
                        defaultValue=""
                        control={control}
                      />
                    </FormGroupField>
                  </FormGroup>
                ) : input.type === "file" ? (
                  <FormGroup key={input.id}>
                    <FormGroupLabel>
                      {input.label}
                    </FormGroupLabel>
                    <FormGroupField>
                      {/* <Controller
                        name={input.inputName}
                        control={control}
                        render={({ field }) => <FileInputField {...field} />}
                      /> */}
                      <input type="file" name={input.inputName} id={input.inputName} />
                    </FormGroupField>
                  </FormGroup>
                ) : input.type === "select" ? (
                  <FormGroup key={input.id}>
                    <FormGroupLabel>{input.label}</FormGroupLabel>
                    <FormGroupField>
                      <Select
                        defaultValue={
                          input.options.find(item => item.selected)
                        }
                        onChange={input.onChangeHandler}
                        className='mx-2'
                        options={input.options.map(option => {
                          return {
                            value: option.value,
                            label: option.label
                          }
                        })}
                        name={input.name}
                        id={input.name}
                        styles={{
                            container: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? 'grey' : 'red',
                                width: "25%",
                                fontSize: ".75rem",
                                lineHeight: "1.5"
                            }),
                        }}
                      />
                    </FormGroupField>
                  </FormGroup>
                ) : input.type === "textarea" ? (
                  <FormGroup key={input.id}>
                    <FormGroupLabel>{input.label}</FormGroupLabel>
                    <FormGroupField>
                      <textarea
                        name={input.inputName}
                        type={input.type}
                        placeholder={input.placeholder || ""}
                        {...register(input.inputName)}
                        className={input.classes}
                        dir={input.dir || "ltr"}
                      ></textarea>
                    </FormGroupField>
                  </FormGroup>
                ) : input.type === "buttons" ? (
                  <FormGroup key={input.id}>
                    <FormGroupLabel>
                    </FormGroupLabel>
                    <FormGroupField>
                      <Button
                        type="button"
                        variant="primary"
                        onClick={input.func1}
                      >
                        {input.value1}
                      </Button>
                      {input.hasAttach && (
                        <Button
                          type="button"
                          variant="secondary"
                          onClick={input.func2}
                        >
                          {input.value2}
                        </Button>
                      )}
                    </FormGroupField>
                  </FormGroup>
                ) : input.type === "color-picker" ? (
                  <FormGroup key={input.id}>
                    <FormGroupLabel>
                      {input.label}
                    </FormGroupLabel>
                    <FormGroupField>
                      <ChromeColorPickerField onChange={input.handleChange} name={input.name} defaultValue={input.defaultValue} />
                    </FormGroupField>
                  </FormGroup>
                ) : (
                  <FormGroup key={input.id}>
                    <FormGroupLabel>
                      {input.label}
                    </FormGroupLabel>
                    <FormGroupField>
                      <div className='d-flex'>
                        {input.radioList.map(item => (
                          <label className="ms-0 me-3 d-flex align-items-center" style={{ color: "#fff" }} key={`index_${item.id}`}>
                            <input
                              name={input.inputName}
                              type={input.type}
                              value={item.id}
                              label={item.label}
                              placeholder={item.label || ""}
                              {...register(input.inputName)}
                              style={{ marginRight: 4 }}
                            />
                            {item.label}
                          </label>
                        ))}
                      </div>
                    </FormGroupField>
                  </FormGroup>
                )
              )
            }


            <div className='d-flex justify-content-end w-100'>
              <Button variant="primary" type="submit">Submit</Button>
              <Button
                type="button"
                variant="secondary"
                onClick={cancleHandler}
              >
                Cancel
              </Button>
            </div>
          </FormContainer>
        </CardBody>
      </Card>
    </Col>
  );
};

Form.propTypes = {
  isHorizontal: PropTypes.bool,
  initValue: PropTypes.object,
  inputs: PropTypes.array,
  submitHandler: PropTypes.func,
  cancleHandler: PropTypes.func,
};

Form.defaultProps = {
  isHorizontal: false,
};

export default Form;

// const SelectElement = styled.select`
//   min-width: 20%;
//   height: 2rem;
//   background-color: transparent;
//   border-radius: 0;
//   border: 1px solid #33333a;
//   color: ${colorText};

//   &:focus-visible{
//     border-radius: 0;
//     border: 1px solid #33333a;
//     outline: 0;
//   }

//   option{
//     padding: 2rem 1rem;
//     background-color: #33333a;
//     border: non;
//     color: #dddddd;
//   }
// `
