import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import TopbarLanguage from '../components/topbar/TopbarLanguage';
// import TopbarMail from '../components/topbar/TopbarMail';
// import TopbarProfile from '../components/topbar/TopbarProfile';
// import TopbarNotification from '../components/topbar/TopbarNotification';
// import TopbarSearch from '../components/topbar/TopbarSearch';
import TopbarSidebarButton from '../components/topbar/TopbarSidebarButton';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  TopbarCenter,
  TopbarContainer,
  TopbarLeft,
  TopbarLogo,
  TopbarRight,
  // TopbarRightOver,
  // TopbarSearchWrap,
} from '../components/topbar/BasicTopbarComponents';
import axios from 'axios';
import baseUrl from '../../../axiosBase';
// import TopbarWallet from '../components/topbar/TopbarWallet';

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  collapse,
}) => {
  const token = useSelector(state => state.user.token);
  const [date, setData] = useState({});

  useEffect(() => {
    getUserData()
  }, [])

  const getUserData = () => {

    axios.get(`${baseUrl}/profile`, {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(result => {
      setData(result.data)
    })

  }
  return (
    <TopbarContainer>
      <TopbarLeft>
        <TopbarSidebarButton
          onClickMobile={changeMobileSidebarVisibility}
          onClickDesktop={changeSidebarVisibility}
        />
        <TopbarLogo to="/" className={collapse ? "d-none" : ""} />
      </TopbarLeft>
      <TopbarCenter>
        <h4>
          HurryApp Technical Support (HTS)
        </h4>
      </TopbarCenter>
      <TopbarRight>
        <Link to="/profile"><span className="lnr lnr-user"></span> {date.name}</Link>
      </TopbarRight>
    </TopbarContainer>
  )
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
  collapse: PropTypes.bool
};

export default Topbar;
