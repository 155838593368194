import React, {
  useEffect,
  useState
} from 'react';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
// import ProfileMain from '../../../containers/Account/Profile/components/ProfileMain';
import styled from 'styled-components';
import { colorAccent } from '@/utils/palette';
import Summary from '../../components/summary/Summary';
import axios from 'axios';
import { useSelector } from 'react-redux';
import baseUrl from '../../axiosBase';
// import { Col } from 'react-bootstrap';

const Profile = () => {
  const token = useSelector(state => state.user.token);


  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getUserData()
  }, [])


  const getUserData = () => {

    setIsLoading(true)
    axios.get(`${baseUrl}/profile`, {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(result => {
      setUserData(result.data)
      setIsLoading(false)
    })
  }
  return isLoading ? ( // isFetching
      <div className="text-center">
        <TableSpinner animation="border" />
      </div>
    ) : (
    <Container>
      <Row>
        <Summary
          labels={[
            {
              id: 0,
              title: "User Id:",
              value: userData.id,
            },
            {
              id: 1,
              title: "Name:",
              value: userData.name,
            },
            {
              id: 2,
              title: "Email:",
              value: userData.email,
            },
            {
              id: 3,
              title: "Role:",
              value: userData.type,
            },
          ]}
          title="User Details"
          isEditable={false}
        />
      </Row>
    </Container>
    )
};

export default Profile;


// region STYLES

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;
