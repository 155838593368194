import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
// import ProfileMain from '../../../containers/Account/Profile/components/ProfileMain';
import { colorAccent } from '@/utils/palette';
import styled from 'styled-components';
import {
  Card, CardBody, CardTitleWrap, CardTitle
} from '@/shared/components/Card';
import { useHistory } from 'react-router-dom';
import Form from '../../../components/form/Form';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import baseUrl from '../../../axiosBase';
import { changeMenuCounter } from '../../../redux/actions/sidebarActions';


const TaskCreate = () => {
  const dispatch = useDispatch();

  const token = useSelector(state => state.user.token);
  const rule = useSelector(state => state.user.rule);
  const history = useHistory();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [departmentSelect, setDepartmentSelect] = useState(null);
  const [typeSelect, setTypeSelect] = useState(null);
  const [prioritySelect, setPrioritySelect] = useState(null);
  const [categorySelect, setCategorySelect] = useState(null);
  const [attachments, setAttachments] = useState([]);

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getData();
    setIsLoading(false)
  }, [])

  const getData = () => {
    axios.all([
      axios.get(
        `${baseUrl}/departments`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ),
      axios.get(
        `${baseUrl}/categories`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ),
      axios.get(
        `${baseUrl}/types`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ),
      axios.get(
        `${baseUrl}/priorities`,
        {
          headers: {
            'accept': 'application/json',
            
            'Authorization': `Bearer ${token}`
          }
        }
      ),
    ]).then(result => {
      setDepartmentList(result[0].data.data)
      setCategoryList(result[1].data.data)
      setTypeList(result[2].data.data)
      setPriorityList(result[3].data.data)
    }).catch(error => {
      console.log(error[1].response)
    })
  }
  
  const submitHandler = async values => {
    
    setIsLoadingSubmit(true)
    setError(null)
    if(
      !values.description_en ||
      !departmentSelect ||
      !categorySelect ||
      !typeSelect ||
      !prioritySelect
    ){
      setIsLoadingSubmit(false)
      setError("All fields are required")
      return;
    }
    if(values.title.length > 255){
      setIsLoadingSubmit(false)
      setError("Title is too long")
      return;
    }
    if(
      isLoadingSubmit
    ){
      setIsLoadingSubmit(false)
      setError("Can't Submit Untill End")
      return;
    }
    const formdata = new FormData();
    formdata.append('_method', 'post');
    formdata.append("title", values.title);
    if(values.description_admin){
      formdata.append("description_admin", values.description_admin);
    }
    formdata.append("description_en", values.description_en);
    formdata.append("description_ar", values.description_ar);
    // const files = [];
    attachments.forEach(attachment => {
      const fileInput = document.getElementById(attachment.inputName);
      if(fileInput.files[0]){
        formdata.append("attachments[]", fileInput.files[0]);
      }
    })
    
    formdata.append("department_id", departmentSelect);
    formdata.append("category_id", categorySelect);
    formdata.append("type_id", typeSelect);
    formdata.append("priority_id", prioritySelect);
    
    try{

      axios.post(`${baseUrl}/tasks`, formdata, {
        headers: {
          'accept': 'application/json',
          'content-type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      }).then(result => {
        setIsLoadingSubmit(false)
        dispatch(
          changeMenuCounter({
            tasks: result.data.counters.open_tasks,
            open: result.data.counters.ongoing_tasks,
            upcoming: result.data.counters.new,
            archived: result.data.counters.archived,
            postponed: result.data.counters.postponed,
            approval: result.data.counters.approval,
            approved: result.data.counters.approved,
            alert: result.data.counters.alert,
          })
        )
        history.push(`/tasks`)
      }).catch(error => {
        setIsLoadingSubmit(false)
        console.log(error.response)
      })

    }catch(error) {
      setIsLoadingSubmit(false)
      console.log(error.responce)
    }
  }

  const cancleHandler = () => {
    history.push(`/tasks`)
  }
  const addAttachmentHandler = () => {
    let manuallyOpened = false;
    setAttachments(prev => {
      if (prev.length === 0){
        return [
          ...prev,
          {
            id: "4_" + prev.length,
            type: "file",
            label: "Attachment " + (prev.length + 1),
            inputName: "attachment_" + prev.length
          }
        ]
      }else if(prev.length > 0){
        const fileInput = document.getElementById(prev[prev.length - 1].inputName);
        if (fileInput.files.length === 0 && !manuallyOpened) {
          manuallyOpened = true;
          fileInput.click();
          return [...prev];
        } else if(fileInput.files.length > 0 && !manuallyOpened) {
          // No file selected
          return [
            ...prev,
            {
              id: "4_" + prev.length,
              type: "file",
              label: "Attachment " + (prev.length + 1),
              inputName: "attachment_" + prev.length
            }
          ]
        }else{
          return [...prev];
        }
      }
      
    })
  }
  const removeAttachmentHandler = () => {
    setAttachments(prev => {
      return prev.filter(item => item.id !== prev[prev.length - 1].id);
    })
  }
  return (
    <Container>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <CardTitle>Add New Task</CardTitle>
              </CardTitleWrap>
              <Alert
                variant="danger"
                show={!!error}
              >
                {error}
              </Alert>
              {
                isLoadingSubmit && ( // isFetching
                  <SpinnerContainer>
                    <TableSpinner animation="border" />
                  </SpinnerContainer>
                )
              }
              {
                isLoading ? ( // isFetching
                  <div className="text-center">
                    <TableSpinner animation="border" />
                  </div>
                ) : (
                  <Form
                    isHorizontal={true}
                    submitHandler={submitHandler}
                    cancleHandler={cancleHandler}
                    initValue={{}}
                    inputs={[
                      {
                        id: 0,
                        label: "Title",
                        inputName: "title",
                        type: "text",
                        placeholder: "Enter Task Title"
                      },
                      ...(rule === "tech team" || rule === "config" ? [{
                        id: "1_1",
                        label: "Description [Admin]",
                        inputName: "description_admin",
                        type: "textarea",
                        // value: taskData.description_ar,
                        placeholder: "Enter Task Admin Description"
                      }] : []),
                      {
                        id: 1,
                        label: "Description [EN]",
                        inputName: "description_en",
                        type: "textarea",
                        placeholder: "Enter Task Description"
                      },
                      {
                        id: "1_2",
                        label: "Description [AR]",
                        inputName: "description_ar",
                        type: "textarea",
                        placeholder: "Enter Task Description In Arabic",
                        classes: "text-end",
                        dir: "rtl"
                      },
                      {
                        id: 2,
                        label: "Department",
                        inputName: "department",
                        type: "select",
                        onChangeHandler: e => setDepartmentSelect(e.value),
                        options: departmentList.map((item, index) => {
                          return {
                            id: item.id,
                            selected: index === 0,
                            value: item.id,
                            label: item.name,
                          }
                        })
                      },
                      {
                        id: 21,
                        label: "Type",
                        inputName: "type",
                        type: "select",
                        onChangeHandler: e => setTypeSelect(e.value),
                        options: typeList.map((item, index) => {
                          return {
                            id: item.id,
                            selected: index === 0,
                            value: item.id,
                            label: item.name,
                          }
                        })
                      },
                      {
                        id: 22,
                        label: "Priority",
                        inputName: "priority",
                        type: "select",
                        onChangeHandler: e => setPrioritySelect(e.value),
                        options: priorityList.map((item, index) => {
                          return {
                            id: item.id,
                            selected: index === 0,
                            value: item.id,
                            label: item.name,
                          }
                        })
                      },
                      {
                        id: 3,
                        label: "Category",
                        inputName: "category",
                        type: "select",
                        onChangeHandler: e => setCategorySelect(e.value),
                        options: categoryList.map((item, index) => {
                          return {
                            id: item.id,
                            selected: index === 0,
                            value: item.id,
                            label: item.name,
                          }
                        })
                      },
                      ...attachments,
                      {
                        id: 5,
                        type: "buttons",
                        value1: "Add Attatchment",
                        value2: "Remove Attatchment",
                        func1: addAttachmentHandler,
                        func2: removeAttachmentHandler,
                        hasAttach: attachments.length > 0
                      },
                    ]}
                  />
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default TaskCreate;


// region STYLES

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;

const SpinnerContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #33333333;
  display: flex;
  justify-content: center;
  align-items: center;
`;