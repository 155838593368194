import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import Select from 'react-select';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';
import { useSelector } from 'react-redux';
import { colorAdditional, colorText } from '@/utils/palette';
import {
 paddingLeft, marginLeft, left,
} from '@/utils/directions';

const Summary = ({
  labels,
  title,
  isEditable = false,
  editFunc = () => {}
}) => {
  const theme = useSelector(state => state.theme.className);

  return (
  <Col md={12} lg={12} xl={12}>
    <Card className="p-0">
      <CardBody>
        <ProjectSummaryWrap>
          <ProjectSummaryCardTitleWrap>
            {title ? <CardTitle>{title}</CardTitle> : <div></div>}
            {
              isEditable && (
                <ProjectSummaryButton variant="outline-secondary" size="sm" onClick={editFunc}>
                  Edit
                </ProjectSummaryButton>
              )
            }
            {
              labels.filter(item => item.title === "Status:").length > 0 && (
                <div className="status-select">
                  <Select
                    onChange={labels.filter(item => item.title === "Status:")[0].handleSubmitStatusModul}
                    className='mx-2'
                    options={labels.filter(item => item.title === "Status:")[0].statusList.map(option => {
                      return {
                        value: option.id,
                        label: option.name
                      }
                    })}
                    defaultValue={labels.filter(item => item.title === "Status:")[0].value}
                    name="status-select"
                    styles={{
                        container: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? 'grey' : 'red',
                            fontSize: ".75rem",
                            lineHeight: "1.5"
                        }),
                    }}
                  />
                </div>
              )
            }
          </ProjectSummaryCardTitleWrap>
          <ProjectSummaryInfo className={theme}>
            <tbody>
              {
                labels.map(label => {
                  if(label.title !== "Status:"){
                    
                    if(
                      label.title == "Description [Admin]:" ||
                      label.title == "Description [en]:" ||
                      label.title == "Description [ar]:" ||
                      label.title == "Title:"
                    ){
                      return (
                        <tr key={label.id}>
                          <th>{label.title}</th>
                          <td style={{whiteSpace: 'pre-wrap',}}>
                            <Card height="auto" className="pb-2">
                              <CardBody
                                colored="true"
                                dir={label.title == "Description [ar]:" ? "rtl" : "ltr"}
                                className={`py-1 ${label.title == "Description [ar]:" ? "text-end" : ""}`}
                                style={{
                                  maxWidth: "800px",
                                  overflow: "hidden"
                                }}
                              >
                                {label.value}
                              </CardBody>
                            </Card>
                          </td>
                        </tr>
                      )
                    }
                    if(
                      label.title == "Created By:"
                    ){
                      return (
                        <tr key={label.id}>
                          <th>{label.title}</th>
                          <td style={{
                            textTransform: "capitalize"
                          }}>{label.value}</td>
                        </tr>
                      )
                    }
                    return (
                      <tr key={label.id}>
                        <th>{label.title}</th>
                        <td>{label.value}</td>
                      </tr>
                    )
                  }
                })
              }
            </tbody>
          </ProjectSummaryInfo>
        </ProjectSummaryWrap>
      </CardBody>
    </Card>
  </Col>
)};

Summary.propTypes = {
  labels: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  editFunc: PropTypes.func,
};

export default Summary;

// region STYLES

const ProjectSummaryWrap = styled.div`
  position: relative;

  hr {
    margin: 30px 0;
  }
`;

const ProjectSummaryCardTitleWrap = styled(CardTitleWrap)`
  margin-bottom: 0;
  
  .status-select{
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
  }
  .status{
    height: 2rem;
    padding: 0 .5rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 600;

    &.status-1{
      color: #007dfa;
      background-color: #9bccff;
    }
    &.status-2{
      color: #0056ad;
      background-color: #77b7fc;
    }
    &.status-3{
      color: #FFA800;
      background-color: #ffe1a6;
    }
    &.status-4{
      color: #8950FC;
      background-color: #c9aeff;
    }
    &.status-5{
      color: #F64E60;
      background-color: #ffadb5;
    }
    &.status-6{
      color: #5e5e5e;
      background-color: #e2e2e2;
    }
    &.status-7{
      color: #f67e4e;
      background-color: #ffc8b3;
    }
    &.status-8{
      color: #046246;
      background-color: #18ca95;
    }
    &.status-9{
      color: #F64E60;
      background-color: #ffadb5;
    }
  }
`;

const ProjectSummaryButton = styled(Button)`
  width: 100px;
  ${marginLeft}: 5px;
  margin-bottom: 0;
`;

const ProjectSummaryInfo = styled.table`
  margin-bottom: 5px;
  text-align: ${left};
  &.light th{
    color: #000
  }

  td {
    ${paddingLeft}: 10px;
    color: ${colorText};
  }

  th {
    color: ${colorAdditional};
    font-weight: 800;
    vertical-align: top;
  }

  td, th {
    padding-bottom: 5px;
    font-size: 1rem;
  }
`;

// endregion
