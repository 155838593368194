import React, {
  useEffect,
  useState
} from 'react';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
// import ProfileMain from '../../../containers/Account/Profile/components/ProfileMain';
import styled from 'styled-components';
import { colorAccent } from '@/utils/palette';
import {
  Card, CardBody
} from '@/shared/components/Card';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Summary from '../../../components/summary/Summary';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Modal from './note';
import BasicCarouselWithoutCaption from './BasicCarouselWithoutCaption';
import baseUrl from '../../../axiosBase';
import { changeMenuCounter } from '../../../redux/actions/sidebarActions';
import ModalUpdate from './note_update';
import toast, { Toaster } from 'react-hot-toast';
import NewAttachment from './newAttachment';
// import { Modal as BootstrapModal, Form } from 'react-bootstrap';
// import { Col } from 'react-bootstrap';

export const getStatusClass = (id) => {
  let result = "";
  switch(id){
    case 1:
      result = "status-1";
    break;
    case 2:
      result = "status-2";
    break;
    case 3:
      result = "status-3";
    break;
    case 4:
      result = "status-4";
    break;
    case 5:
      result = "status-5";
    break;
    case 6:
      result = "status-6";
    break;
    case 7:
      result = "status-7";
    break;
    case 8:
      result = "status-8";
    break;
    case 9:
      result = "status-9";
    break;
    default:
      result = "status-1";
    break
  }
  return result;
}

const TaskViewId = () => {
  const dispatch = useDispatch();
  
  const params = useParams();
  const token = useSelector(state => state.user.token);
  const rule = useSelector(state => state.user.rule);


  const [taskData, setTaskData] = useState({})
  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isAlert, setIsAlert] = useState(false);
  const [showNewAttachment, setShowNewAttachment] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const confirmAddAlert = () => toast.success("Alert added to task successfully");
  const confirmRemoveAlert = () => toast.error("Alert removed from task successfully");

  const confirmStateChange = () => toast.success("Status Changed Successfully");

  const showSwal = () => {
    setShowNewAttachment(prev => !prev);
  }
  
  useEffect(() => {
    getTaskData()
    axios.get(
      `${baseUrl}/statuses`,
      {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }
    ).then(result => {
      setStatusList(result.data.data)
    })

    axios.get(`${baseUrl}/profile`, {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(result => {
      setUserData(result.data)
    })
  }, [])


  const getTaskData = () => {
    setIsLoading(true)
    axios.get(`${baseUrl}/tasks/${params.task_id}`, {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(result => {
      setTaskData(result.data.data)
      setIsAlert(result.data.data.has_note)
      setIsLoading(false)
    })
  }
  const handleConfirmAddNote = (values) => {

    axios.post(`${baseUrl}/tasks/${params.task_id}/notes`,
    {
      note: values
    },{
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(result => {
      getTaskData(result)
    })
  }
  const handleConfirmEditNote = (id, values) => {

    axios.post(`${baseUrl}/note/${id}`,
    {
      note: values
    },{
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(result => {
      getTaskData(result)
    })
  }
  const needAproval = () => {
    let approvalId = 1;
    if(taskData.approval.id === 1){
      approvalId = 2
    }else if(taskData.approval.id === 2){
      approvalId = 3
    }
    if(taskData.approval.id !== 3){
      axios.post(`${baseUrl}/tasks/${params.task_id}`,
      {
        approval_id: approvalId
      },{
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      }).then(result => {
        dispatch(
          changeMenuCounter({
            tasks: result.data.counters.open_tasks,
            open: result.data.counters.ongoing_tasks,
            upcoming: result.data.counters.new,
            archived: result.data.counters.archived,
            postponed: result.data.counters.postponed,
            approval: result.data.counters.approval,
            approved: result.data.counters.approved,
            alert: result.data.counters.alert,
          })
        )
        getTaskData()
      })
    }
  }

  
  const handleSubmitStatusModul = (e) => {
    
    axios.post(`${baseUrl}/tasks/${params.task_id}`,
    {
      "status_id": e.value
    },{
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(result => {
      if(result.status == 200){
        confirmStateChange()
      }
      dispatch(
        changeMenuCounter({
          tasks: result.data.counters.open_tasks,
          open: result.data.counters.ongoing_tasks,
          upcoming: result.data.counters.new,
          archived: result.data.counters.archived,
          postponed: result.data.counters.postponed,
          approval: result.data.counters.approval,
          approved: result.data.counters.approved,
          alert: result.data.counters.alert,
        })
      )
    })
  }
  const handleToggleAlert = () => {
    axios.post(`${baseUrl}/toggle-has-note`,
    {
      task_id: params.task_id
    },{
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(result => {
      isAlert ? confirmRemoveAlert() : confirmAddAlert()
      setIsAlert(result.data.has_note);
      axios.get(
        `${baseUrl}/tasks?menu_counter`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).then(result => {
        dispatch(
          changeMenuCounter({
            tasks: result.data.open_tasks,
            open: result.data.ongoing_tasks,
            upcoming: result.data.new,
            archived: result.data.archived,
            postponed: result.data.postponed,
            approval: result.data.approval,
            approved: result.data.approved,
            alert: result.data.alert,
          })
        )
      })
    })
  }
  return isLoading ? ( // isFetching
      <div className="text-center">
        <TableSpinner animation="border" />
      </div>
    ) : (<>
    <Toaster />
    <Container>
      <h4 className='mb-2 d-flex justify-content-between'>
        <span>
          Task Details:&nbsp;
          {
            taskData.approval.id === 2 ? "(Need Aproval)" :
              taskData.approval.id === 3 ? "(Approved)" : ""
          }
        </span>
        
        <div>
          <button
            type='button'
            className={`btn ${isAlert ? "btn-success" : "btn-outline-success"} py-1`}
            onClick={handleToggleAlert}
          >
            <span className="lnr lnr-alarm"></span>
          </button>
          <Link to={`/task/${params.task_id}/edit`} className="btn btn-primary py-1 mx-2 border-0">Edit</Link>
          <span
            style={{
              border: "1px solid #999",
              borderRadius: ".35rem"
            }}
            className='p-1'
          >#{taskData.id}</span>
        </div>
      </h4>
      <Row>
        <Summary
          labels={[
            // {
            //   id: 0,
            //   title: "Id:",
            //   value: taskData.id,
            // },
            {
              id: 8,
              title: "Created By:",
              value: `${taskData.user.name} (${taskData.user.type})`,
            },
            {
              id: 3,
              title: "Status:",
              value: {
                value: taskData.status?.id,
                label: taskData.status?.name
              } || {
                value: 1,
                label: "Submited"
              },
              classes: getStatusClass(taskData.status?.id),
              handleSubmitStatusModul: handleSubmitStatusModul,
              statusList: statusList
            },
            {
              id: 4,
              title: "Category:",
              value: taskData.category?.name,
            },
            {
              id: 5,
              title: "Department:",
              value: taskData.department?.name,
            },
            {
              id: 51,
              title: "Type:",
              value: taskData.type?.name,
            },
            {
              id: 52,
              title: "Priority:",
              value: taskData.priority?.name,
            },
            {
              id: 6,
              title: "Date:",
              value: moment(taskData.created_at).format("DD MMM yyyy"),
            },
            {
              id: 7,
              title: "Time:",
              value: moment(taskData.created_at).format("h:mm A"),
            },
            {
              id: 11,
              title: "Title:",
              value: taskData.title,
            },
            {
              id: 121,
              title: "Description [Admin]:",
              value: taskData.description_admin === "null" ||
                taskData.description_admin === null ||
                taskData.description_admin === undefined ? " " : taskData.description_admin,
            },
            {
              id: 122,
              title: "Description [en]:",
              value: taskData.description_en === "null" ||
              taskData.description_en === null ||
              taskData.description_en === undefined ? " " : taskData.description_en,
            },
            {
              id: 123,
              title: "Description [ar]:",
              value: taskData.description_ar === "null" ||
              taskData.description_ar === null ||
              taskData.description_ar === undefined ? " " : taskData.description_ar,
            },
          ]}
          title=""
          isEditable={false}
        />
      </Row>
      <h4 className='my-3'>Attachments: <span className='btn btn-success btn-sm me-1 float-end' onClick={showSwal}>Add New</span></h4>
      <Row>
        <BasicCarouselWithoutCaption
          images={
            taskData.attachments?.filter(item => {
              const imageTypes = ["png", "jpg", "jpeg"]
              const urlArray = item.path.split(".");
              const brefex = urlArray[urlArray.length - 1];
              return imageTypes.includes(brefex)
            }).map(item => {
              return {
                id: item.id,
                src: item.path.replace("localhost", "192.168.1.14")
              }
            }) || []
          }
          voices={
            taskData.attachments?.filter(item => {
              const imageTypes = ["mp3"]
              const urlArray = item.path.split(".");
              const brefex = urlArray[urlArray.length - 1];
              return imageTypes.includes(brefex)
            }).map(item => {
              return {
                id: item.id,
                src: item.path.replace("localhost", "192.168.1.14")
              }
            }) || []
          }
          videos={
            taskData?.attachments?.filter(item => {
              const imageTypes = ["mp4", "3gp", "ogg"]
              const urlArray = item.path.split(".");
              const brefex = urlArray[urlArray.length - 1];
              return imageTypes.includes(brefex)
            }).map(item => {
              return {
                id: item.id,
                src: item.path.replace("localhost", "192.168.1.14")
              }
            })
          }
        />
      </Row>
      {
        taskData.attachments?.filter(item => {
          const imageTypes = ["png", "jpg", "jpeg", "mp3", "mp4", "3gp", "ogg"]
          const urlArray = item.path.split(".");
          const brefex = urlArray[urlArray.length - 1];
          return !imageTypes.includes(brefex)
        }).length > 0 && (
          <Row>
            <Card>
              <CardBody>
                <ol>
                  {
                    taskData.attachments?.filter(item => {
                      const imageTypes = ["png", "jpg", "jpeg", "mp3", "mp4", "3gp", "ogg"]
                      const urlArray = item.path.split(".");
                      const brefex = urlArray[urlArray.length - 1];
                      return !imageTypes.includes(brefex)
                    }).map(item => {
                      const fileName = item.path.split("/").filter(item => item.includes(".") && item != "api.tec.hurryapps.com")[0]
                      return <li key={item.id}><a href={item.path.replace("localhost", "192.168.1.14")} download={fileName} target='_blank' rel='noreferrer'>{fileName}</a></li>
                    })
                  }
                </ol>
              </CardBody>
            </Card>
          </Row>
        )
      }
      <h4 className='my-3'>Notes:</h4>
      <StyledCardContainer>
        {
          taskData.notes?.map(item => {
            return (
              <Card key={item.id}>
                <CardBody>
                  <span className={`side-stick ${item.user.type}`}></span>
                  <h5 className="note-title text-truncate mb-0 d-flex justify-content-between align-items-center">
                    <span>{item.user?.name} ({item.user.type})</span>
                    {
                      item.user?.id == userData.id ?
                      <ModalUpdate
                        btn="Edit"
                        confirm={handleConfirmEditNote}
                        color="primary"
                        id={item.id}
                        content={item.note}
                      /> :
                      ""
                    }
                  </h5>
                  <p className="note-date font-12 text-muted">{moment(item.created_at).format("DD MMM YYYY hh:mm A")}</p>
                  <div className="note-content">
                    <p
                      className="note-inner-content text-muted"
                    >
                      {item.note}
                    </p>
                  </div>
                </CardBody>
              </Card>
            )
          })
        }
      </StyledCardContainer>
      <div
        className='d-flex justify-content-between'
      >
        <Modal
          btn="Add Note"
          confirm={handleConfirmAddNote}
          color="success"
        />
        {
          (rule === "tech team" || rule === "config") && taskData.approval.id === 1 && (
            <span
              className="btn text-white btn-warning"
              onClick={needAproval}
            >
              Need Approval
            </span>
          )
        }
        {
          rule === "config" && taskData.approval.id === 2 && (
            <span
              className="btn text-white btn-success"
              onClick={needAproval}
            >
              Approve
            </span>
          )
        }
      </div>
      <p style={{paddingTop: 20}}></p>
    </Container>
    
      <NewAttachment
        color="success"
        show={showNewAttachment}
        setShow={setShowNewAttachment}
        refreshFunc={getTaskData}
      />
    </>
    )
};

export default TaskViewId;


// region STYLES

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;

const StyledCardContainer = styled.div`
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 0 solid transparent;
    border-radius: 0;
    padding-bottom: 10px !important;
  }
  .card-body {
      flex: 1 1 auto;
      padding: 1.57rem;
  }

  .nav-link {
      padding: .5rem
  }

  .card {
      border-radius: 10px
  }

  .favourite-note {
      cursor: pointer
  }

  .side-stick {
      position: absolute;
      width: 3px;
      height: 35px;
      left: 0;
  }

  .point {
      color: rgba(44, 208, 126, 1)
  }

  .side-stick.tech.team {
      background-color: #1c8551
  }
  .side-stick.operation {
      background-color: #ff4861
  }
`