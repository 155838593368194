import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
// import ProfileMain from '../../../containers/Account/Profile/components/ProfileMain';
import { colorAccent } from '@/utils/palette';
import styled from 'styled-components';
import {
  Card, CardBody, CardTitleWrap, CardTitle
} from '@/shared/components/Card';
import { useHistory } from 'react-router-dom';
import Form from '../../../components/form/Form';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import baseUrl from '../../../axiosBase';


const UserEdit = () => {
  const token = useSelector(state => state.user.token);
  const rule = useSelector(state => state.user.rule);
  const params = useParams();
  const history = useHistory();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [errorGetData, setErrorGetData] = useState(null);
  const [data, setData] = useState({});
  const [type, setType] = useState("tech team");

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if(rule !== "tech team" && rule !== "config"){
        history.push(`/tasks`)
    }
    getData()
  }, []);

  const getData = () => {
    setIsLoading(true)
    axios.get(
      `${baseUrl}/users/${params.user_id}`,
      {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }
    ).then(result => {
      setData(result.data)
      setType(result.data.type)
      setIsLoading(false)
    }).catch(error => {
      setErrorGetData(error.response.data.message)
      setIsLoading(false)
    })
  }

  const submitHandler = async values => {
    
    setIsLoadingSubmit(true)
    setError(null)
    if(
      !values.name ||
      !values.email ||
      !type
    ){
      setIsLoadingSubmit(false)
      setError("All fields are required")
      return;
    }
    if(
      isLoadingSubmit
    ){
      setIsLoadingSubmit(false)
      setError("Can't Submit Untill End")
      return;
    }

    const data = {
      "name" : values.name,
      "email": values.email,
      "type": type
    }
    if(values.password){
      data.password = values.password;
    }
    
    axios.put(`${baseUrl}/users/${params.user_id}`, data, {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(result => {
      if(result.state == 200){
        console.log("ok")
      }
      setIsLoadingSubmit(false)
      history.push(`/users`)
    }).catch(error => {
      setIsLoadingSubmit(false)
      setError(error.response.data.message)
    })
  }

  const cancleHandler = () => {
    history.push(`/users`)
  }
  const roles = [
    {
      id: 1,
      selected: data.type === "tech team",
      value: "tech team",
      label: "Tech Team",
    },
    {
      id: 2,
      selected: data.type === "operation",
      value: "operation",
      label: "Operation",
    },
  ]

  if(rule === "config"){
    roles[2] = {
      id: 3,
      selected: data.type === "config",
      value: "config",
      label: "Config",
    }
  }

  return (
    <Container>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <CardTitle>Add New User</CardTitle>
              </CardTitleWrap>
              <Alert
                variant="danger"
                show={!!error}
              >
                {error}
              </Alert>
              {
                isLoadingSubmit && ( // isFetching
                  <SpinnerContainer>
                    <TableSpinner animation="border" />
                  </SpinnerContainer>
                )
              }
              {
                isLoading ? ( // isFetching
                  <div className="text-center">
                    <TableSpinner animation="border" />
                  </div>
                ) : errorGetData === null ?(
                  <Form
                    isHorizontal={true}
                    submitHandler={submitHandler}
                    cancleHandler={cancleHandler}
                    initValue={{
                      name: data.name || "",
                      email: data.email || "",
                      password: data.password || "",
                    }}
                    inputs={[
                      {
                        id: 1,
                        label: "Name",
                        inputName: "name",
                        type: "text",
                        placeholder: "Enter User Name"
                      },
                      {
                        id: 2,
                        label: "Email",
                        inputName: "email",
                        type: "email",
                        placeholder: "Enter User Email"
                      },
                      {
                        id: 3,
                        label: "Password",
                        inputName: "password",
                        type: "password",
                        placeholder: "Enter User Password"
                      },
                      {
                        id: 4,
                        label: "Role",
                        inputName: "type",
                        type: "select",
                        onChangeHandler: e => setType(e.value),
                        options: roles
                      },
                    ]}
                  />
                ) :  (
                  <div className='h5 text-white'>{errorGetData}</div>
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default UserEdit;


// region STYLES

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;

const SpinnerContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #33333333;
  display: flex;
  justify-content: center;
  align-items: center;
`;