import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'; //useLocation
import MainWrapper from '../MainWrapper';
// import Landing from '../../Landing/index';
import NotFound404 from '../../DefaultPage/404/index';
import LockScreen from '../../Account/LockScreen/index';
import LogIn, { deleteCookie } from '../../Account/LogIn/index';
// import LogInPhoto from '../../Account/LogInPhoto/index';
// import Register from '../../Account/Register/index';
// import RegisterPhoto from '../../Account/RegisterPhoto/index';
import ResetPassword from '../../Account/ResetPassword/index';
// import ResetPasswordPhoto from '../../Account/ResetPasswordPhoto';
import WrappedRoutes from './WrappedRoutes';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { auth } from '../../../redux/actions/authActions';
import axios from 'axios';
import baseUrl from '../../../axiosBase';
import { changeMenuCounter } from '../../../redux/actions/sidebarActions';

export const LogOut = () => {
  const token = useSelector(state => state.user.token);
  const history  = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    axios.get(`${baseUrl}/logout`, {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(result => {
      if(result.status == 200){
        deleteCookie("token")
        dispatch(auth({ token: null }));
        history.push("/log_in")
      }
    }).catch(error => {
      console.log(error.response)
      deleteCookie("token")
      dispatch(auth({ token: null }));
      history.push("/log_in")
    })
  }, [])
  return (
    <>
    </>
  );
}

const Router = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector(state => state.user.token);

  useEffect(() => {
    axios.get(`${baseUrl}/tasks?menu_counter`,
    {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(result => {
      dispatch(
        changeMenuCounter({
          tasks: result.data.open_tasks,
          open: result.data.ongoing_tasks,
          upcoming: result.data.new,
          archived: result.data.archived,
          postponed: result.data.postponed,
          approval: result.data.approval,
          approved: result.data.approved,
          alert: result.data.alert,
        })
      )
    })
  }, [location])

  return (
    <MainWrapper>
      <main>
        <Switch>
          {/* <Route exact path="/" component={Landing} /> */}
          <Route path="/404" component={NotFound404} />
          <Route path="/lock_screen" component={LockScreen} />
          <Route path="/log_in" component={LogIn} />
          <Route path="/log_out" component={LogOut} />
          <Route path="/reset_password" component={ResetPassword} />
          <Route path="/" component={WrappedRoutes} />
        </Switch>
      </main>
    </MainWrapper>
  )
};

export default Router;
