import React, {useEffect, useState} from 'react';
import { Route, useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { colorBackgroundBody } from '@/utils/palette';
import { paddingLeft } from '@/utils/directions';
import { colorAccent } from '@/utils/palette';
import Layout from '../../../Layout/index';
// my pages
import Tasks from '../../../../pages/tasks';
import { useSelector } from 'react-redux';
import { deleteCookie } from '../../../Account/LogIn';
import { getUserToken } from '../../../../redux/reducers/authReducer';
import { useDispatch } from 'react-redux';
import { auth } from '../../../../redux/actions/authActions';
import TaskCreate from '../../../../pages/tasks/create';
import TaskEdit from '../../../../pages/tasks/edit';
import TaskViewId from '../../../../pages/tasks/view';
import CategoriesList from '../../../../pages/categories/CategoriesList';
import CategoryCreate from '../../../../pages/categories/create';
import CategoryEdit from '../../../../pages/categories/edit';
import DepartmentsList from '../../../../pages/department/DepartmentsList';
import DepartmentCreate from '../../../../pages/department/create';
import DepartmentEdit from '../../../../pages/department/edit';
import StatusesList from '../../../../pages/status/StatusesList';
import StatusEdit from '../../../../pages/status/edit';
import StatusCreate from '../../../../pages/status/create';
import UsersList from '../../../../pages/users/Users';
import UserCreate from '../../../../pages/users/create';
import UserEdit from '../../../../pages/users/edit';
import UserView from '../../../../pages/users/view';
import HomePage from '../../../../pages/dashboard';
import ArchivedTasks from '../../../../pages/archived';
import UpcomingTasks from '../../../../pages/upcoming';
import axios from 'axios';
import baseUrl from '../../../../axiosBase';
import { Spinner } from 'react-bootstrap';
import { changeMenuCounter } from '../../../../redux/actions/sidebarActions';
import TypesList from '../../../../pages/type/TypesList';
import TypeCreate from '../../../../pages/type/create';
import TypeEdit from '../../../../pages/type/edit';
import PrioritiesList from '../../../../pages/priority/PrioritiesList';
import PriorityCreate from '../../../../pages/priority/create';
import PriorityEdit from '../../../../pages/priority/edit';
import ApprovalTasks from '../../../../pages/approvals';
import Profile from '../../../../pages/profile';
import ApprovedTasks from '../../../../pages/approved';
import ArchiveModal from './ArchiveModal';
import DeleteModal from './DeleteModal';
import AlertTasks from '../../../../pages/alert';
import OpenTasks from '../../../../pages/open';
import PostponedTasks from '../../../../pages/postponed';

const Links = () => {
  const history  = useHistory();
  const location = useLocation();
  const token = useSelector(state => state.user.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if(token === null) {
      deleteCookie("token")
      history.push('/log_in')
    }
    if(getUserToken() == null){
      dispatch(auth({ token: null }));
      history.push('/log_in');
    }
  }, [location])

  return (
    <div>
      <Layout />
      <ContainerWrap>
        {/* my pages */}
        <Route path="/" exact component={HomePage} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/tasks" exact component={Tasks} />
        <Route path="/archived" exact component={ArchivedTasks} />
        <Route path="/postponed" exact component={PostponedTasks} />
        <Route path="/approval" exact component={ApprovalTasks} />
        <Route path="/approved" exact component={ApprovedTasks} />
        <Route path="/upcoming" exact component={UpcomingTasks} />
        <Route path="/alert" exact component={AlertTasks} />
        <Route path="/open" exact component={OpenTasks} />
        <Route path="/tasks/create" exact component={TaskCreate} />
        <Route path="/task/:task_id" exact component={TaskViewId} />
        <Route path="/task/:task_id/edit" exact component={TaskEdit} />
        <Route path="/task/:task_id/archive" exact component={Archive} />
        <Route path="/task/:task_id/delete" exact component={Delete} />
        <Route path="/categories" exact component={CategoriesList} />
        <Route path="/categories/create" exact component={CategoryCreate} />
        <Route path="/category/:category_id/edit" exact component={CategoryEdit} />
        <Route path="/departments" exact component={DepartmentsList} />
        <Route path="/departments/create" exact component={DepartmentCreate} />
        <Route path="/department/:department_id/edit" exact component={DepartmentEdit} />
        <Route path="/statuses" exact component={StatusesList} />
        <Route path="/statuses/create" exact component={StatusCreate} />
        <Route path="/status/:department_id/edit" exact component={StatusEdit} />
        <Route path="/users" exact component={UsersList} />
        <Route path="/users/create" exact component={UserCreate} />
        <Route path="/user/:user_id/edit" exact component={UserEdit} />
        <Route path="/user/:user_id" exact component={UserView} />
        <Route path="/types" exact component={TypesList} />
        <Route path="/types/create" exact component={TypeCreate} />
        <Route path="/type/:type_id/edit" exact component={TypeEdit} />
        <Route path="/priorities" exact component={PrioritiesList} />
        <Route path="/priorities/create" exact component={PriorityCreate} />
        <Route path="/priority/:priority_id/edit" exact component={PriorityEdit} />
        
      </ContainerWrap>
    </div>
  )
};

export default Links;
// region STYLES

const ContainerWrap = styled.div`
  padding-top: 80px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  ${paddingLeft}: 0;

  background: ${colorBackgroundBody};

  @media screen and (min-width: 576px) {
    ${paddingLeft}: 250px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 150px;
  }
`;

// endregion

const Archive = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const token = useSelector(state => state.user.token);
  const history  = useHistory();
  const [data, setData] = useState({});

  useEffect(() => {
    axios.get(`${baseUrl}/tasks/${params.task_id}`,
      {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      }).then(result => {
        setData(result.data.data)

      })
  }, []);

  const handleConfirmFilter = () => {
    axios.post(`${baseUrl}/tasks/${params.task_id}`,
      {"status_id": 9},
      {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      }).then(result => {
        dispatch(
          changeMenuCounter({
            tasks: result.data.counters.open_tasks,
            open: result.data.counters.ongoing_tasks,
            upcoming: result.data.counters.new,
            archived: result.data.counters.archived,
            postponed: result.data.counters.postponed,
            approval: result.data.counters.approval,
            approved: result.data.counters.approved,
            alert: result.data.counters.alert,
          })
        )
      }).then(() => {
        history.push("/tasks")
      })
  }

  return (
    <div className="text-center">
      <TableSpinner animation="border" />
      <ArchiveModal
        confirm={handleConfirmFilter}
        color=""
        header="Are you sure want to archive"
        title={data.title}
      />
    </div>
  )
}
const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;

const Delete = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const token = useSelector(state => state.user.token);
  const history  = useHistory();
  const [data, setData] = useState({});

  useEffect(() => {
    axios.get(`${baseUrl}/tasks/${params.task_id}`,
      {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      }).then(result => {
        setData(result.data.data)

      })
  }, []);

  const handleConfirmFilter = () => {
    axios.delete(`${baseUrl}/tasks/${params.task_id}`,
      {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      }).then(result => {
        dispatch(
          changeMenuCounter({
            tasks: result.data.counters.open_tasks,
            open: result.data.counters.ongoing_tasks,
            upcoming: result.data.counters.new,
            archived: result.data.counters.archived,
            postponed: result.data.counters.postponed,
            approval: result.data.counters.approval,
            approved: result.data.counters.approved,
            alert: result.data.counters.alert,
          })
        )
      }).then(() => {
        history.push("/archived")
      })
  }

  return (
    <div className="text-center">
      <TableSpinner animation="border" />
      <DeleteModal
        confirm={handleConfirmFilter}
        color=""
        header="Are you sure want to delete"
        title={data.title}
      />
    </div>
  )
}