import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import FiEdit from 'mdi-react/SquareEditOutlineIcon';
import ApiTable from '../../components/table/DataReactTable';
import styled from 'styled-components';
import { Button } from '@/shared/components/Button';
import { useHistory } from 'react-router-dom';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import Modal from '../../components/modal';
import { useReducer } from 'react';
import axios from 'axios';
import baseUrl from '../../axiosBase';

let firstRender = true;

const columns = [
    {
        id: 1,
        title: "Id"
    },
    {
        id: 2,
        title: "Name"
    },
    {
        id: 3,
        title: "Actions"
    },
]
const rowsPerPage = 20;

const urlReducer = (currentUrl, action) => {
    const base = `${baseUrl}/categories?page=1`;
    let finalUrl = base;
    switch(action.type){
        case 'change page':
            finalUrl = currentUrl.replace(/page=[0-9]*]/gi, `page=${action.page}`) 
        break;
        case 'filter':
            finalUrl = base + action.filterString;
        break;
    }
    return finalUrl;
}

const CategoriesList = () => {
    const history = useHistory();
    const token = useSelector(state => state.user.token);
    const rule = useSelector(state => state.user.rule);
    const [apiUrl, dispatchUrl] = useReducer(urlReducer, `${baseUrl}/categories?page=1`)
    const [dataRows, setDataRows] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [dataLengthInPages, setDataLengthInPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteCategory, setDeleteCategory] = useState({});
    const [error, setError] = useState({
        hasError: false,
        errorMsg: ""
    });

    useEffect(() => {
        if(rule !== "tech team" && rule !== "config"){
            history.push(`/tasks`)
        }
    }, [])
    useEffect(() => {
        getCategories();

    }, [
        apiUrl
    ])
    useEffect(() => {
        if(firstRender) {
            firstRender = false;
        }else{
            setDataRows(prev => {
                const result = prev.map(item => {
                    const actionArray = item.actions?.map(action => {
                        if(action.type === "delete"){
                            return {
                                ...action,
                                icon: (
                                    <Modal
                                        color="danger"
                                        title="Stop!"
                                        btn={<DeleteForeverIcon className='ms-3 text-danger' style={{cursor: "pointer"}}/>}
                                        confirm={handleDeleteCategory}
                                        type="icon"
                                        message={'Are You Sure You Want To Delete ' + deleteCategory.name}
                                    />
                                )
                            }
                        }
                        return action
                    })
                    return {
                        ...item,
                        actions: actionArray,
                    };
                })
                return result
            })
        }
    }, [deleteCategory]);

    const getCategories = () => {

        try {
            axios.get(apiUrl, {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }).then(result => {
                setDataRows(() => {
                    const pagedData = result.data.data;
                    const rows = pagedData.map((item) => {
                        return {
                            id: item.id,
                            actions: [
                                {
                                    id: 1,
                                    link: '/category/' + item.id + '/edit',
                                    icon: <FiEdit className='ms-2' />
                                },
                                {
                                    id: 2,
                                    link: '#',
                                    type: 'delete',
                                    icon: (
                                        <Modal
                                          color="danger"
                                          title="Stop!"
                                          btn={<DeleteForeverIcon className='ms-3 text-danger' style={{cursor: "pointer"}}/>}
                                          confirm={handleDeleteCategory}
                                          type="icon"
                                          message={'Are You Sure You Want To Delete ' + deleteCategory.name}
                                        />
                                    )
                                },
                            ],
                            columns: [
                                {
                                    id: 1,
                                    data: "#" + item.id
                                },
                                {
                                    id: 2,
                                    data: item.name
                                },
                            ],
                            name: item.name
                        }
                    })
                    return rows
                })
                setDataLengthInPages(Math.ceil(result.data.data.length / rowsPerPage))
                setIsLoading(false)
            })
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }
    const handleChangePage = (val) => {
        setIsLoading(true)
        dispatchUrl({
            type: 'change page',
            page: val
        })
        setCurrentPage(val)
    }

    const handleAddNewCategory = () => {
        history.push(`/categories/create`)
    }

    const handleDeleteCategory = () => {
        setIsLoading(true)
        axios.delete(`${baseUrl}/categories/` + deleteCategory.id, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(result => {
            getCategories(result);
        }).catch(error => {
            setError({
                hasError: true,
                errorMsg: error.response.data.message
            })
            getCategories();
        })
    }
    
    return (
        <Container>
            {
                error.hasError && (
                    <Row className='justify-content-center'>
                        <Col md="6">
                        <Alert variant="danger" className='position-relative'> 
                            <Alert.Heading className='text-center p-3 m-0 h6'> 
                                {error.errorMsg}
                            </Alert.Heading>
                            <span
                                className='ms-auto text-danger h6'
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: 10,
                                    right: 10
                                }}
                                onClick={() => setError({
                                    hasError: false,
                                    errorMsg: ""
                                })}
                            >X</span>
                        </Alert> 
                        </Col>
                    </Row>
                )
            }
            <Row>
                <Col md={12} className='position-relative'>
                    <ProfileButton onClick={handleAddNewCategory}>
                        <span style={{color: "#fff"}}>Add New</span>
                    </ProfileButton>
                </Col>
                <ApiTable
                    Title="Categories List"
                    columns={columns}
                    dataRows={dataRows}
                    isLoading={isLoading}
                    getRowItem= {category => setDeleteCategory(category)}

                    currentPage={currentPage}
                    dataLengthInPages={dataLengthInPages}
                    handleChange={handleChangePage}
                />
            </Row>
        </Container>
    );
};

export default CategoriesList;


const ProfileButton = styled(Button)`
    margin-bottom: 0;
    padding: 8px 15px;
    background-color: #1c8551;
    border: none;
    position: absolute;
    right: 35px;
    top: 10px;
    z-index: 99;
`;