import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Container,
  Row,
  Spinner,
} from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { colorAccent } from '@/utils/palette';
import PaginationComponent from './Pagination';
import { Link } from 'react-router-dom';
import Sort from 'mdi-react/SortIcon';
import SortAscending from 'mdi-react/SortAscendingIcon';
import SortDescending from 'mdi-react/SortDescendingIcon';


function trimText(text, maxLength) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

const ApiTable = ({
  Title,
  columns,
  dataRows,
  isLoading,
  // pagination
  currentPage,
  dataLengthInPages,
  handleChange,
  getRowItem= () => {},
}) => {
  const [sortBy, setSortBy] = useState({
    column: "created_at",
    type: "desc"
  });

  const getIcon = (column) => {
    if(column === sortBy.column){
      if(sortBy.type === "desc"){
        return <SortDescending style={{
          height: "1rem",
          marginRight: 4
        }}/>
      }else{
        return <SortAscending style={{
          height: "1rem",
          marginRight: 4
        }}/>
      }
    }else{
      return <Sort style={{
        height: "1rem",
        marginRight: 4
      }}/>
    }
  }

  const handleSort = (column) => {
    setSortBy(prev => {
      if(isLoading){
        return prev
      }
      if(column === prev.column){
        if(prev.type === "desc"){
          const result = {
            ...prev,
            type: "asc"
          }
          dataRows.setSort(result)
          return result
        }else{
          const result = {
            ...prev,
            type: "desc"
          }
          dataRows.setSort(result)
          return result
        }
      }else{
        const result = {
          column: column,
          type: "desc"
        }
        dataRows.setSort(result)
        return result
      }
    })
  }

  return (
    <Container>
      <Row>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <CardTitle>{Title}</CardTitle>
              </CardTitleWrap>
              <Table responsive>
                <thead>
                  <tr>
                    {
                      columns.map(column => (
                        <th
                          key={column.id}
                          style={{
                            width: column.title === "Actions" ? "100px" : "auto"
                          }}
                          onClick={column.hasSort ? () => handleSort(column.column) : () => {}}
                        >
                          <span className='d-flex'>
                            {
                              column.hasSort && getIcon(column.column)
                            }
                            {column.title}
                          </span>
                        </th>
                      ))
                    }
                  </tr>
                </thead>
                {dataRows.length > 0 && !isLoading ? ( // pokemon && !isFetching && (
                  <>
                    <tbody>
                      {dataRows.map(row => ( //pokemon.map(elem => (
                        <tr key={row.id}>
                          {
                            row.columns.map(column => {
                              
                              if(column.type === "status"){
                                return(
                                  <td
                                    key={column.id}
                                    className={`${
                                      column.class === "text-normal" ? "" : 'first-letter-uppercase ' + column.class
                                    }`}
                                  >
                                    <CustomLabel
                                      className={`status-label`}
                                      color={column.statusColor}
                                      bgColor={column.statusBgColor}
                                    >{column.data}</CustomLabel>
                                  </td>
                                )
                              }
                              return (
                                <td key={column.id} title={column.trim ? column.data : ""} className={`${column.class === "text-normal" ? "" : 'first-letter-uppercase ' + column.class
                                  }`}>{column.trim ? trimText(column.data, 30) : column.data}</td>
                              )
                            })
                          }
                          <td>
                            {
                              row.actions?.map(action => {
                                if(action.type === "delete"){
                                  return <span key={action.id} onClick={() => getRowItem(row)}>{action.icon}</span>
                                }
                                return (
                                  <Link to={`${action.link}`} key={action.id}>
                                    {action.icon}
                                  </Link>
                                )
                              })
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={columns.length}>
                          <div className="d-flex align-items-center">
                            <PaginationComponent
                              currentPage={currentPage}
                              dataLengthInPages={dataLengthInPages}
                              handleChange={handleChange}
                            />
                            <div className='ms-2'>
                              {dataRows.length} Rows
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={columns.length} className='text-center h5'>No data available</td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {
                isLoading && ( // isFetching
                  <div className="text-center">
                    <TableSpinner animation="border" />
                  </div>
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ApiTable;


ApiTable.propTypes = {
  Title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  dataRows: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  // pagination
  currentPage: PropTypes.number.isRequired,
  dataLengthInPages: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  getRowItem: PropTypes.func,
};
// region STYLES

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;
const CustomLabel = styled.span`
  padding: 0.25rem .5rem;
  border-radius: 5px;

  &.status-label{
    color: ${(props) => props.color || '#007dfa'};
    background-color: ${(props) => props.bgColor || '#9bccff'};
  }
  &.status-label.status-2{
    color: #0056ad;
    background-color: #77b7fc;
  }
  &.status-label.status-3{
    color: #FFA800;
    background-color: #ffe1a6;
  }
  &.status-label.status-6{
    color: #5e5e5e;
    background-color: #e2e2e2;
  }
  &.status-label.status-7{
    color: #f67e4e;
    background-color: #ffc8b3;
  }
  &.status-label.status-4{
    color: #8950FC;
    background-color: #c9aeff;
  }
  &.status-label.status-5,
  &.status-label.status-11,
  &.status-label.status-9{
    color: #F64E60;
    background-color: #ffadb5;
  }
  &.status-label.status-8{
    color: #046246;
    background-color: #18ca95;
  }
`;

// endregion
