import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import FiEdit from 'mdi-react/SquareEditOutlineIcon';
import ApiTable from '../../components/table/DataReactTable';
import axios from 'axios';
import moment from 'moment';
import baseUrl from '../../axiosBase';
import DeleteIcon  from 'mdi-react/DeleteOutlineIcon';

const columns = [
    {
        id: 1,
        title: "Id",
        column: "id",
        hasSort: true
    },
    {
        id: 2,
        title: "Title",
        column: "title",
        hasSort: true
    },
    {
        id: 3,
        title: "Date",
        column: "created_at",
        hasSort: true
    },
    {
        id: 4,
        title: "Department",
        column: "department.name",
        hasSort: true
    },
    {
        id: 5,
        title: "Category",
        column: "category.name",
        hasSort: true
    },
    {
        id: 6,
        title: "Status",
        column: "status.name",
        hasSort: true
    },
    {
        id: 7,
        title: "Actions"
    },
]
const rowsPerPage = 15;

const urlReducer = (currentUrl, action) => {
    const baseUri = `${baseUrl}/tasks?page=1&open_tasks`;
    let finalUrl = baseUri;
    switch(action.type){
        case 'change page':
            finalUrl = currentUrl.replace(/page=[0-9]*/gi, `page=${action.page}`) 
        break;
        case 'filter':
            finalUrl = baseUri + action.filterString;
        break;
    }
    return finalUrl;
}

const handleStatusColors = (colorJson) => {
    const cRGBA = JSON.parse(colorJson)?.rgb || null;
    const cText = cRGBA ? `rgba(${cRGBA.r}, ${cRGBA.g}, ${cRGBA.b}, ${cRGBA.a})` : null;

    return cText
}

const OpenTable = () => {
    const token = useSelector(state => state.user.token);
    const rule = useSelector(state => state.user.rule);
    const [apiUrl, dispatchUrl] = useReducer(urlReducer, `${baseUrl}/tasks?page=1&open_tasks`)
    const [dataRows, setDataRows] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [dataLengthInPages, setDataLengthInPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    // sort
    const [sortBy, setSortBy] = useState({
        column: "created_at",
        type: "desc"
    });
    useEffect(() => {
        
        let sort = "";
        
        if(sortBy.column !== ""){
            sort = `&sort=${sortBy.column}&${sortBy.type}`
        }

        dispatchUrl({
            type: 'filter',
            filterString: sort
        })

    }, [
        sortBy,
    ])
    useEffect(() => {
        getTasks();

    }, [
        apiUrl
    ])

    const getTasks = () => {

        try {
            axios.get(apiUrl, {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }).then(result => {
                setDataRows(() => {
                    // const pagedData = result.data.data
                    const rows = result.data.data.map((item) => {
                        const actions = [
                            {
                                id: 0,
                                link: '/task/' + item.id,
                                icon: <EyeOutlineIcon />
                            },
                        ]
    
                        if(rule === "tech team" || rule === "config"){
                            actions.push({
                                id: 1,
                                link: '/task/' + item.id + '/edit',
                                icon: <FiEdit className='ms-2' />
                            })
                            actions.push({
                                id: 2,
                                link: '/task/' + item.id + '/archive',
                                icon: <DeleteIcon className='text-warning ms-1' />
                            })
                        }
                        return {
                            id: item.id,
                            actions: actions,
                            sort: sortBy,
                            setSort: setSortBy,
                            columns: [
                                {
                                    id: 1,
                                    data: "#" + item.id
                                },
                                {
                                    id: 2,
                                    data: item.title,
                                    trim: true
                                },
                                {
                                    id: 3,
                                    data: moment(item.created_at).format("DD MMM yyyy"),
                                    class: "text-normal"
                                },
                                {
                                    id: 4,
                                    data: item.department?.name
                                },
                                {
                                    id: 5,
                                    data: item.category?.name
                                },
                                {
                                    id: 6,
                                    data: item.status?.name,
                                    type: "status",
                                    statusId: item.status?.id,
                                    statusColor: handleStatusColors(item.status?.color) || '#007dfa',
                                    statusBgColor: handleStatusColors(item.status?.bg_color) || '#9bccff',
                                },
                            ],
                            name: item.name
                        }
                    })
                    rows.setSort = setSortBy
                    return rows
                })
                setDataLengthInPages(Math.ceil(result.data.total / rowsPerPage))
                setIsLoading(false)
            })
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }
    const handleChangePage = (val) => {
        setIsLoading(true)
        dispatchUrl({
            type: 'change page',
            page: val
        })
        setCurrentPage(val)
    }

    return (
        <Row>
            <ApiTable
                Title="Open Tasks"
                columns={columns}
                dataRows={dataRows}
                isLoading={isLoading}
                // getRowItem= {member => setDeleteTask(member)}
                currentPage={currentPage}
                dataLengthInPages={dataLengthInPages}
                handleChange={handleChangePage}
            />
        </Row>
    );
};

export default OpenTable;