import {
  CHANGE_SIDEBAR_VISIBILITY,
  CHANGE_MOBILE_SIDEBAR_VISIBILITY,
  CHANGE_MENU_COUNTER,
} from '../actions/sidebarActions';


const initialState = {
  show: false,
  collapse: false,
  counters: {
    tasks: 0,
    upcoming: 0,
    archived: 0,
    approval: 0,
    approved: 0,
  },
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SIDEBAR_VISIBILITY:
      return { ...state, collapse: !state.collapse };
    case CHANGE_MOBILE_SIDEBAR_VISIBILITY:
      return { ...state, show: !state.show };
    case CHANGE_MENU_COUNTER:
      return { ...state, counters: action.counters };
    default:
      return state;
  }
};

export default sidebarReducer;
