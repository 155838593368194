import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Card } from '@/shared/components/Card';
import { colorRed, colorBlue } from '@/utils/palette'; //colorGreen, colorYellow
import {
  DashboardBookingCard,
  DashboardBookingDescription,
  DashboardBookingTitle,
  DashboardBookingTotalWrap,
} from '../../containers/Dashboards/Booking/BookingCardDashboardElements';
import ProgressBar from '@/shared/components/ProgressBar';

const StatusCard = ({status, title, color, percent, hasPercentBar=true}) => {
    let textColor = "#000";
    switch (color) {
        case "blue":
            textColor = colorBlue;
            break;
        case "turquoise":
            textColor = "#1c8551";
            break;
        case "pink":
            textColor = colorRed;
            break;
        case "lime":
            textColor = "#ff8b00";
            break;
        default:
            textColor = "#000";
            break;
    }

    const label = Math.round(percent) + "%";
    return (
        <Col md={12} xl={3} lg={6} xs={12}>
            <Card>
                <DashboardBookingCard>
                    <DashboardBookingTotalWrap>
                        <DashboardBookingTitle color={textColor}>
                            {status}
                        </DashboardBookingTitle>
                    </DashboardBookingTotalWrap>
                    <DashboardBookingDescription>{title}</DashboardBookingDescription>
                    {hasPercentBar && <ProgressBar now={percent} label={label} rounded size="small" gradient={color} top />}
                </DashboardBookingCard>
            </Card>
        </Col>
    )
};

StatusCard.propTypes = {
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    color: PropTypes.string,
    percent: PropTypes.number,
    hasPercentBar: PropTypes.bool
}
export default StatusCard;
