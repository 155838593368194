import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import FiEdit from 'mdi-react/SquareEditOutlineIcon';
import { useHistory } from 'react-router-dom';
import ApiTable from '../../components/table/DataReactTable';
import Modal from './date-filter';
import axios from 'axios';
import moment from 'moment';
import baseUrl from '../../axiosBase';
import AsyncSelect from 'react-select/async';
import DeleteIcon  from 'mdi-react/DeleteOutlineIcon';

const columns = [
    {
        id: 1,
        title: "Id",
        column: "id",
        hasSort: true
    },
    {
        id: 2,
        title: "Title",
        column: "title",
        hasSort: true
    },
    {
        id: 3,
        title: "Date",
        column: "created_at",
        hasSort: true
    },
    {
        id: 4,
        title: "Department",
        column: "department.name",
        hasSort: true
    },
    {
        id: 5,
        title: "Category",
        column: "category.name",
        hasSort: true
    },
    {
        id: 7,
        title: "Read"
    },
    {
        id: 8,
        title: "Actions"
    },
]
const rowsPerPage = 15;

const urlReducer = (currentUrl, action) => {
    const baseUri = `${baseUrl}/tasks?page=1&need_approval`;
    let finalUrl = baseUri;
    switch(action.type){
        case 'change page':
            finalUrl = currentUrl.replace(/page=[0-9]*/gi, `page=${action.page}`) 
        break;
        case 'filter':
            finalUrl = baseUri + action.filterString;
        break;
    }
    return finalUrl;
}

const ApprovalTasks = () => {
    const token = useSelector(state => state.user.token);
    const rule = useSelector(state => state.user.rule);
    // const counters = useSelector(state => state.sidebar.counters);
    const history = useHistory();

    const [apiUrl, dispatchUrl] = useReducer(urlReducer, `${baseUrl}/tasks?page=1&need_approval`)
    const [dataRows, setDataRows] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [dataLengthInPages, setDataLengthInPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);

    // filters
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState({value: "all", label: "All Departments"});
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({value: "all", label: "All Categories"});
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({value: "all", label: "All Users"});
    const [dateFilter, setDateFilter] = useState("");
    const [searchByTitle, setSearchByTitle] = useState("");
    const [tasksCount, setTasksCount] = useState(0);
    // sort
    const [sortBy, setSortBy] = useState({
        column: "created_at",
        type: "desc"
    });
    

    useEffect(() => {
        getTasks();

    }, [
        apiUrl
    ])

    // get filter data
    useEffect(() => {
        if(rule !== "tech team" && rule !== "config"){
            history.push(`/tasks`)
        }
        axios.get(
            `${baseUrl}/filters-data`,
            {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }
        ).then(result => {
            setDepartments(() => {
                return [
                    {
                        id: "all",
                        name: "All Departments"
                    },
                    ...result.data.departments
                ]
            });
            setCategories(() => {
                return [
                    {
                        id: "all",
                        name: "All Categories"
                    },
                    ...result.data.categories
                ]
            });
            setUsers(() => {
                return [
                    {
                        id: "all",
                        name: "All Users"
                    },
                    ...result.data.users
                ]
            });
        })
    }, [])

    // handle change filter
    useEffect(() => {
        let department = "";
        
        if(selectedDepartment.value !== "all"){
            department = `&department_id=${selectedDepartment.value}`
        }

        let catecory = "";
        
        if(selectedCategory.value !== "all"){
            catecory = `&category_id=${selectedCategory.value}`
        }

        let user = "";
        
        if(selectedUser.value !== "all"){
            user = `&created_by=${selectedUser.value}`
        }

        let q = "";
        
        if(searchByTitle !== ""){
            if(searchByTitle[0] == "#"){
                q =`&id=${searchByTitle.replace("#", "")}`
            }else{
                q = `&q=${searchByTitle}`
            }
        }

        let sort = "";
        
        if(sortBy.column !== ""){
            sort = `&sort=${sortBy.column}&${sortBy.type}`
        }

        dispatchUrl({
            type: 'filter',
            filterString: dateFilter + department + catecory + user + sort + q
        })

    }, [
        selectedDepartment,
        selectedCategory,
        selectedUser,
        dateFilter,
        searchByTitle,
        sortBy,
    ])

    const getTasks = () => {

        try {
            axios.get(apiUrl, {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }).then(result => {
                setDataRows(() => {
                    // const pagedData = result.data.data
                    const rows = result.data.data.map((item) => {
                        const actions = [
                            {
                                id: 0,
                                link: '/task/' + item.id,
                                icon: <EyeOutlineIcon />
                            },
                        ]
    
                        if(rule === "tech team" || rule === "config"){
                            actions.push({
                                id: 1,
                                link: '/task/' + item.id + '/edit',
                                icon: <FiEdit className='ms-2' />
                            })
                            actions.push({
                                id: 2,
                                link: '/task/' + item.id + '/archive',
                                icon: <DeleteIcon className='text-warning ms-1' />
                            })
                        }
                        return {
                            id: item.id,
                            actions: actions,
                            sort: sortBy,
                            setSort: setSortBy,
                            columns: [
                                {
                                    id: 1,
                                    data: "#" + item.id
                                },
                                {
                                    id: 2,
                                    data: item.title,
                                    trim: true
                                },
                                {
                                    id: 3,
                                    data: moment(item.created_at).format("DD MMM yyyy"),
                                    class: "text-normal"
                                },
                                {
                                    id: 4,
                                    data: item.department?.name
                                },
                                {
                                    id: 5,
                                    data: item.category?.name
                                },
                                {
                                    id: 7,
                                    data: item.read ?
                                        <span className="lnr lnr-checkmark-circle" style={{
                                            fontSize: "1.25rem",
                                            fontWeight: "bold"
                                        }}></span> :
                                        <span className="lnr lnr-cross-circle" style={{
                                            fontSize: "1.25rem",
                                            fontWeight: "bold"
                                        }}></span>,
                                    class: item.read ? "text-success fw-bold" : "text-danger fw-bold",
                                },
                            ],
                            name: item.name
                        }
                    })
                    rows.setSort = setSortBy
                    return rows
                })
                setDataLengthInPages(Math.ceil(result.data.total / rowsPerPage))
                setIsLoading(false)
                setTasksCount(result.data.total)
            })
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }
    const handleChangePage = (val) => {
        setIsLoading(true)
        dispatchUrl({
            type: 'change page',
            page: val
        })
        setCurrentPage(val)
    }

    const handleConfirmFilter = (values) => {
        setIsLoading(true)
        setDateFilter(values);
        setCurrentPage(1)
    }
    
    const searchInDepartments = (search, callback) => {
        axios.get(`${baseUrl}/departments?q=${search}`,{
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(result => {
            callback(result.data.data.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        }).catch(error => {
            console.log(error);
            callback(departments.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        })
    }
    const searchInCategories = (search, callback) => {
        axios.get(`${baseUrl}/categories?q=${search}`,{
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(result => {
            callback(result.data.data.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        }).catch(error => {
            console.log(error.response);
            callback(categories.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        })
    }
    const searchInUsers = (search, callback) => {
        axios.get(`${baseUrl}/users-filter?q=${search}`,{
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(result => {
            callback(result.data.data.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        }).catch(error => {
            console.log(error.response);
            callback(users.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        })
    }
    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={12}>
                    <div className='d-flex align-items-center justify-content-center mb-4'>
                        <Modal
                            btn="Date"
                            confirm={handleConfirmFilter}
                            color=""
                        />
                        
                        <AsyncSelect
                            value={selectedDepartment}
                            onChange={setSelectedDepartment}
                            className='mx-2'
                            defaultOptions={
                                departments.map(item => {
                                    return {
                                        value: item.id,
                                        label: item.name
                                    }
                                })
                            }
                            loadOptions={searchInDepartments}
                            styles={{
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'grey' : 'red',
                                    width: "12%",
                                    fontSize: ".75rem",
                                    lineHeight: "1.5"
                                }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    zIndex: state.isFocused ? '8' : '8',
                                    borderColor: selectedDepartment.value !== "all" ? 'green' : 'grey',
                                    backgroundColor: selectedDepartment.value !== "all" ? 'rgb(141, 235, 170)' : '#fff',
                                    borderWidth: selectedDepartment.value !== "all" ? '2px' : '1px',
                                })
                            }}
                        />
                        <AsyncSelect
                            value={selectedCategory}
                            onChange={setSelectedCategory}
                            className='mx-2'
                            defaultOptions={
                                categories.map(item => {
                                    return {
                                        value: item.id,
                                        label: item.name
                                    }
                                })
                            }
                            loadOptions={searchInCategories}
                            styles={{
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'grey' : 'red',
                                    width: "12%",
                                    fontSize: ".75rem",
                                    lineHeight: "1.5"
                                }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    zIndex: state.isFocused ? '8' : '8',
                                    borderColor: selectedCategory.value !== "all" ? 'green' : 'grey',
                                    backgroundColor: selectedCategory.value !== "all" ? 'rgb(141, 235, 170)' : '#fff',
                                    borderWidth: selectedCategory.value !== "all" ? '2px' : '1px',
                                })
                            }}
                        />
                        <AsyncSelect
                            value={selectedUser}
                            onChange={setSelectedUser}
                            className='mx-2'
                            defaultOptions={
                                users.map(item => {
                                    return {
                                        value: item.id,
                                        label: item.name
                                    }
                                })
                            }
                            loadOptions={searchInUsers}
                            styles={{
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'grey' : 'red',
                                    width: "12%",
                                    fontSize: ".75rem",
                                    lineHeight: "1.5"
                                }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    zIndex: state.isFocused ? '8' : '8',
                                    borderColor: selectedUser.value !== "all" ? 'green' : 'grey',
                                    backgroundColor: selectedUser.value !== "all" ? 'rgb(141, 235, 170)' : '#fff',
                                    borderWidth: selectedUser.value !== "all" ? '2px' : '1px',
                                })
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12} className='position-relative'>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search By Title Or ID (ID ex #1)"
                            value={searchByTitle}
                            onChange={e => setSearchByTitle(e.target.value)}
                        />
                    </div>
                </Col>
                <ApiTable
                    Title={`Need Approval Tasks (${tasksCount})`}
                    columns={columns}
                    dataRows={dataRows}
                    isLoading={isLoading}
                    // getRowItem= {member => setDeleteTask(member)}
                    currentPage={currentPage}
                    dataLengthInPages={dataLengthInPages}
                    handleChange={handleChangePage}
                />
            </Row>
        </Container>
    );
};

export default ApprovalTasks;