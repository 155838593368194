import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal as BootstrapModal, Form, Row, Col } from 'react-bootstrap';
import { Button, ButtonToolbar } from '@/shared/components/Button';
import styled from 'styled-components';
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
} from '@/utils/palette';
import {
  flexFlow, left, paddingLeft, paddingRight, right,
} from '@/utils/directions';
import moment from 'moment';

const Modal = ({
  color,
  btn,
  title,
  colored,
  header,
  type = "button",
  confirm= () => {}
}) => {
  const [modal, setModal] = useState(false);
  const [allDates, setAllDates] = useState(true);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const [isCustom, setIsCustom] = useState(false);

  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const buttonVariant = colored ? `outline-${color}` : color;
  const handleConfirm = async () => {
    let filterText = "";
    if(!allDates){
      filterText += `&date_start=${
        moment(fromDate).startOf('day').toISOString()
      }&date_end=${
        moment(toDate).endOf('day').toISOString()
      }`
    }


    await confirm(filterText);
    toggle();
  }

  const handleToday = () => {
    setAllDates(false);
    setIsCustom(false);
    setFromDate(moment().format('yyyy-MM-DD'));
    setToDate(moment().format('yyyy-MM-DD'));
  }
  const handleYasterday = () => {
    setAllDates(false);
    setIsCustom(false);
    setFromDate(moment().subtract(1, 'day').format('yyyy-MM-DD'));
    setToDate(moment().subtract(1, 'day').format('yyyy-MM-DD'));
  }
  const handleLast7Days = () => {
    setAllDates(false);
    setIsCustom(false);
    setFromDate(moment().subtract(6, 'day').format('yyyy-MM-DD'));
    setToDate(moment().format('yyyy-MM-DD'));
  }
  const handleLast30Days = () => {
    setAllDates(false);
    setIsCustom(false);
    setFromDate(moment().subtract(29, 'day').format('yyyy-MM-DD'));
    setToDate(moment().format('yyyy-MM-DD'));
  }
  const handleAllTimes = () => {
    setIsCustom(false);
    setAllDates(true);
    setFromDate(moment());
    setToDate(moment());
  }
  const handleCustom = () => {
    setIsCustom(true);
    setAllDates(false);
  }
  return (
    <>
      {type === "icon" && <span onClick={() => {
        toggle();

      }}>{btn}</span>}
      <div>
        {type !== "icon" && <Button variant={color} style={{marginBottom: 0}} onClick={toggle}>{btn}</Button>}
        <StyledModal
          show={modal}
          onHide={toggle}
          color={color}
          colored={colored}
          header={header}
        >
          <ModalHeader>
            <ModalCloseButton
              className="lnr lnr-cross"
              aria-label="close-btn"
              type="button"
              onClick={toggle}
            />
            <ModalTitle>{title}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Row className='m-0'>
              <Col className='p-0' md={12}>
                <ul style={{
                  listStyle: 'none',
                  padding: 0,
                }}>
                <li className='mb-2 '>
                  <span
                    className={`mx-auto d-block btn ${
                      allDates ? 'btn-success' : 'btn-secondary'
                    } py-1`}
                    onClick={handleAllTimes}
                  >All times</span>
                </li>
                  <li className='mb-2 '>
                    <span
                      className={`mx-auto d-block btn ${
                        fromDate == moment().format('yyyy-MM-DD') &&
                        toDate == moment().format('yyyy-MM-DD') &&
                        !isCustom ? 'btn-success' : 'btn-secondary'
                      } py-1`}
                      onClick={handleToday}
                    >Today</span>
                  </li>
                  <li className='mb-2 '>
                    <span
                      className={`mx-auto d-block btn ${
                        fromDate == moment().subtract(1, 'day').format('yyyy-MM-DD') &&
                        toDate == moment().subtract(1, 'day').format('yyyy-MM-DD') &&
                        !isCustom ? 'btn-success' : 'btn-secondary'
                      } py-1`}
                      onClick={handleYasterday}
                    >Yasterday</span>
                  </li>
                  <li className='mb-2 '>
                    <span
                      className={`mx-auto d-block btn ${
                        fromDate == moment().subtract(6, 'day').format('yyyy-MM-DD') &&
                        toDate == moment().format('yyyy-MM-DD') &&
                        !isCustom ? 'btn-success' : 'btn-secondary'
                      } py-1`}
                      onClick={handleLast7Days}
                    >Last 7 days</span>
                  </li>
                  <li className='mb-2 '>
                    <span
                      className={`mx-auto d-block btn ${
                        fromDate == moment().subtract(29, 'day').format('yyyy-MM-DD') &&
                        toDate == moment().format('yyyy-MM-DD') &&
                        !isCustom ? 'btn-success' : 'btn-secondary'
                      } py-1`}
                      onClick={handleLast30Days}
                    >Last 30 days</span>
                  </li>
                  <li className='mb-2 '>
                    <span
                      className={`mx-auto d-block btn ${
                        isCustom ? 'btn-success' : 'btn-secondary'
                      } py-1`}
                      onClick={handleCustom}
                    >Custom</span>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className={isCustom ? '' : 'd-none'}>
              <Col md={12}>
                <Form.Group className="mb-3 d-flex align-items-center">
                  <Form.Label className='mb-0 w-25'>From Date</Form.Label>
                  <Form.Control
                    type="date"
                    disabled={allDates}
                    value={fromDate}
                    onChange={e => setFromDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3 d-flex align-items-center">
                  <Form.Label className='mb-0 w-25'>To Date</Form.Label>
                  <Form.Control
                    type="date"
                    disabled={allDates}
                    value={toDate}
                    onChange={e => setToDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <Button variant="secondary" className="mb-3" onClick={toggle}>Cancel</Button>
            <Button variant={buttonVariant} className="mb-3" onClick={handleConfirm}>Confirm</Button>
          </ModalFooter>
        </StyledModal>
      </div>
    </>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  type: PropTypes.string,
  confirm: PropTypes.func
};

Modal.defaultProps = {
  title: '',
  colored: false,
  header: false,
};

export default Modal;

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }
  
  flex-flow: ${flexFlow} !important;
`;

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`;

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`;

const ModalHeader = styled.div``;
const ModalBody = styled.div``;

const getColor = (color) => {
  switch (color) {
    case 'primary':
      return colorBlue;
    case 'success':
      return colorAccent;
    case 'warning':
      return colorYellow;
    case 'danger':
      return colorRed;

    default:
      return colorText;
  }
};

const StyledModal = styled(BootstrapModal).withConfig({
  shouldForwardProp: prop => !['colored', 'header'].includes(prop),
})`
  
  .modal-dialog {
    max-width: 300px;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 1rem;
    background-color: ${colorBackground};
    color: ${colorText};
  }
  
  ${ModalTitleIcon} {
    color: ${props => getColor(props.color)};
  }

  ${props => props.colored && `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${getColor(props.color)};
    }
  `}

  ${props => props.header && `
    
    .modal-dialog {
      max-width: 520px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${getColor(props.color)};
    }
  `}
`;

// endregion
