import React from 'react';
// import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Card, CardBody
} from '@/shared/components/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import Modal from './date-filter';
import axios from 'axios';
import baseUrl from '../../../axiosBase';


function AttachmentList({attachmentList, getTaskData}) {
    // const params = useParams();
    const token = useSelector(state => state.user.token);

    const handleConfirm = (id) => {
        axios.delete(`${baseUrl}/attachments/${id}`, {
            headers: {
              'accept': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          }).then(result => {
            if(result.status === 200){
                getTaskData()
            }
          })
    }

    return (
        <Row>
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <ul>
                            {
                                attachmentList.map(item => {
                                    const imageTypes = ["png", "jpg", "jpeg"]
                                    const urlArray = item.path.split(".");
                                    const brefex = urlArray[urlArray.length - 1];
                                    const urlFileNameArray = item.path.split("/");
                                    const urlFileName = urlFileNameArray.pop();

                                    return (
                                        <AttachmentItem key={item.id}>
                                            {
                                                imageTypes.includes(brefex) ? (
                                                    <img src={item.path.replace("localhost", "192.168.1.14")} />
                                                ) : (
                                                    <div className='brefex'>{brefex}</div>
                                                )
                                            }
                                            <a href={item.path.replace("localhost", "192.168.1.14")} target='_blank' rel='noreferrer'>{urlFileName}</a>
                                            <Modal
                                                title={`Are You Sure Want To Delete (${urlFileName})`}
                                                btn="Delete"
                                                confirm={() => handleConfirm(item.id)}
                                                color=""
                                            />
                                        </AttachmentItem>
                                    )
                                })
                            }
                        </ul>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default AttachmentList;

AttachmentList.propTypes = {
    attachmentList: PropTypes.array,
    getTaskData: PropTypes.func,
};

const AttachmentItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    &::after{
        position: absolute;
        content: "";
        width: 50%;
        height: 1px;
        left: 25%;
        bottom: 0;
        background-color: #999;
    }

    img, .brefex{
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
    .brefex{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #333;
        color: #fff;
    }
`;
