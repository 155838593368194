import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'; //, useDispatch
// import PropTypes from 'prop-types';
// import withAuthFirebase from '@/shared/components/account/auth/withAuthFirebase';
import LogInForm from '@/shared/components/account/loginForm/LogInForm';
// import showResults from '@/utils/showResults';
import {
  AccountCard,
  AccountContent,
  AccountWrap,
} from '@/shared/components/account/AccountElements';
import Loading from '@/shared/components/Loading';
import axios from 'axios';
import { auth, authError } from '../../../redux/actions/authActions';
import baseUrl from '../../../axiosBase';
import { changeMenuCounter } from '../../../redux/actions/sidebarActions';

// changeIsOpenModalFireBase
const LogIn = () => {
  const history  = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector(state => state.user.token);
  
  const dispatch = useDispatch();

  useEffect(() => {
    if(token !== null) {
      axios.get(
        `${baseUrl}/tasks?menu_counter`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).then(result => {
        dispatch(
          changeMenuCounter({
            tasks: result.data.open_tasks,
            open: result.data.ongoing_tasks,
            upcoming: result.data.new,
            archived: result.data.archived,
            postponed: result.data.postponed,
            approval: result.data.approval,
            approved: result.data.approved,
            alert: result.data.alert,
          })
        )
        history.push('/')
      })
    }
  }, [token])

  const login = async values => {
    setIsLoading(true)
    if(!values.email || !values.password){
      dispatch(authError("Email And Password Inputs Shouldn't Be Empty"));
      setIsLoading(false)
      return;
    }
    try{
      axios.post(`${baseUrl}/login`, values, {
        headers: {
          'accept': 'application/json',
        }
      }).then(result => {
        dispatch(auth({ token: result.data.token, rule: result.data.user.type }));
        setCookie("token", result.data.token, 1)
        setCookie("rule", result.data.user.type, 1)
        setIsLoading(false)
      }).catch((error) => {
        console.log(error)
        dispatch(authError("Wrong Email or Password"));
        setIsLoading(false)
      })
    }catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }
  return (
    <AccountWrap>
      {isLoading && <Loading transparent={true}/>}
      <AccountContent className="d-flex align-items-center">
        <AccountCard >
          <img src={`${process.env.PUBLIC_URL}/img/logo/logo_512.png`} style={{
            width: 200,
            height: "auto",
            display: "block",
            margin: "0 auto"
          }} alt="" />
          <LogInForm
            onSubmit={login}
            form="log_in_form"
            isLoading={isLoading}
          />
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  )
};

LogIn.propTypes = {
  // changeIsOpenModalFireBase: PropTypes.func.isRequired,
};

// export default withAuthFirebase(LogIn);
export default LogIn;

export function setCookie(name, value, daysToLive) {
  const date = new Date();
  date.setTime(date.getTime() + (daysToLive * 24 * 60 * 60 * 1000));
  let expires = "expires=" +  date.toUTCString();
  document.cookie = `${name}=${value}; ${expires}`
}
export function deleteCookie(name) {
  setCookie(name, null, null)
}