import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorBorder, colorBackground, colorHover } from '@/utils/palette';
import { left } from '@/utils/directions';
import SidebarLink, { SidebarLinkTitle } from './SidebarLink';
// import SidebarCategory from './SidebarCategory';
import { useSelector } from 'react-redux';
import { lighten } from 'polished';
import {
  colorAccent,
  colorText,
  sidebarColor,
  colorGray,
} from '@/utils/palette';
import { 
  marginRight,
} from '@/utils/directions';

const SidebarContent = ({
  onClick, changeToLight, changeToDark, collapse,
}) => {
  const rule = useSelector(state => state.user.rule);
  
  const theme = useSelector(state => state.theme.className);
  const counters = useSelector(state => state.sidebar.counters);
  return (
  <SidebarContentWrap collapse={collapse}>
    <SidebarBlock collapse={collapse}>
      {
        (rule === "tech team" || rule === "config") && (
          <>
            <SidebarLink
              title="Dashboard"
              icon="home"
              route="/"
              onClick={onClick}
            />
          </>
        )
      }
      <SidebarLink
        title="Tasks"
        icon="tasks"
        route="/tasks"
        onClick={onClick}
        counter={counters.tasks}
      />
      {
        (rule === "tech team" || rule === "config") && (
          <>
            <SidebarLink
              title="Open Tasks"
              icon="star"
              route="/open"
              onClick={onClick}
              counter={counters.open}
            />
            {/* <SidebarLink
              title="Upcoming"
              icon="star"
              route="/upcoming"
              onClick={onClick}
              counter={counters.upcoming}
            /> */}
            <SidebarLink
              title="Postponed"
              icon="clock"
              route="/postponed"
              onClick={onClick}
              counter={counters.postponed}
            />
            <SidebarLink
              title="Archived"
              icon="trash"
              route="/archived"
              onClick={onClick}
              counter={counters.archived}
            />
            <SidebarLink
              title="Need Approval"
              icon="question-circle"
              route="/approval"
              onClick={onClick}
              counter={counters.approval || 0}
            />
            <SidebarLink
              title="Approved Tasks"
              icon="checkmark-circle"
              route="/approved"
              onClick={onClick}
              counter={counters.approved || 0}
            />
            <SidebarLink
              title="Alert Tasks"
              icon="alarm"
              route="/alert"
              onClick={onClick}
              counter={counters.alert || 0}
            />
            <SidebarLink
              title="Departments"
              icon="apartment"
              route="/departments"
              onClick={onClick}
            />
            <SidebarLink
              title="Categories"
              icon="menu"
              route="/categories"
              onClick={onClick}
            />
            <SidebarLink
              title="Statuses"
              icon="chart-bars"
              route="/statuses"
              onClick={onClick}
            />
            <SidebarLink
              title="Types"
              icon="type"
              route="/types"
              onClick={onClick}
            />
            <SidebarLink
              title="Priorities"
              icon="sort-amount-asc"
              route="/priorities"
              onClick={onClick}
            />
            <SidebarLink
              title="Users"
              icon="user"
              route="/users"
              onClick={onClick}
            />
          </>
        )
      }
      
      
      {
        theme === "light" ? (
          <li onClick={changeToDark}>
            <ThemeSwitch>
              <SidebarLinkIcon className='lnr lnr-layers' />
              <SidebarLinkTitle>
                Dark Theme
              </SidebarLinkTitle>
            </ThemeSwitch>
          </li>
        ) : (
          <li onClick={changeToLight}>
            <ThemeSwitch>
              <SidebarLinkIcon className='lnr lnr-layers' />
              <SidebarLinkTitle>
                Light Theme
              </SidebarLinkTitle>
            </ThemeSwitch>
          </li>
        )
      }
      {/* <SidebarLink
        title="Test Mode"
        icon="cog"
        route="#"
        onClick={onClick}
      /> */}
    </SidebarBlock>

    <SidebarBlock collapse={collapse}>
      <SidebarLink title="Log Out" icon="exit" route="/log_out" />
    </SidebarBlock>
  </SidebarContentWrap>
)
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
  onClick: () => {},
};

export default SidebarContent;

// region STYLES

const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;
  
  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
  
  @media screen and (min-width: 576px) {
    padding-top: 15px;
    
    ${props => props.collapse && `
      width: 60px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`;

const SidebarBlock = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid ${colorBorder};
  list-style-type: none;
  
  &:last-child {
    border: none;
  }
  
  @media screen and (min-width: 576px) {
    
    ${props => props.collapse && `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 60px;
        background: ${colorBackground(props)};
        
        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          ${left(props)}: 70px;
        }
  
        &:hover {
          background: ${colorHover(props)};
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;

const SidebarLinkIcon = styled.span`
  font-size: 14px;
  line-height: 13px;
  color: ${lighten(0.25, colorGray)};
  ${marginRight}: 10px;
`;
const ThemeSwitch = styled.a`
  height: 36px;
  width: 240px;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 11px 20px;
  overflow: hidden;
  background: transparent;
  border: none;
  color: ${colorText};
  text-align: ${left};
  font-size: 14px;

  &.active {
    background: ${sidebarColor};

    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    background: ${colorAccent};
    opacity: 0;
    transition: all 0.3s;
    ${left}: 0;
  }

  &:hover {
    text-decoration: none;
    background-color: ${colorHover};
    color: ${colorText};

    &:before {
      opacity: 1;
    }
  }

  @media screen and (min-width: 576px) {
    width: 100%;

    span {
      position: relative;
      animation: none;
      ${left}: 0;
    }
  }
`;
// endregion

